// VideoPlayerComponent.jsx
import React, { useContext, useEffect, useRef, useState } from 'react';
import constants from '../../const';
import { useButtplugService } from './useButtplugService';
import { useFunscriptAction } from './FunscriptActionService';
import { TvContext } from '../../context/TvContext';

const VideoPlayerComponent = ({ sourceUrl, posterUrl, isVR, vrType, script, getCurrentTime }) => {
  const { tvState, setTvState } = useContext(TvContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [readyToAttach, setreadyToAttach] = useState(false);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const lastEmitTimeRef = useRef(0);
  const emissionIntervalMs = 20;


  const {
    devices,
    stopAllDevices,
    getDevices,
  } = useButtplugService();


  const {
    reset,
    checkTime,
    actions,
    removeFunscript,
    printDevices } = useFunscriptAction();

  useEffect(() => {
    if (actions.length > 0) {
      setIsLoaded(true)
      setreadyToAttach(true)
    }
  }, [actions]);

  useEffect(() => {

  }, [devices]);

  useEffect(() => {
    console.log(devices)
    if (isLoaded & readyToAttach & isVR) {
      const video = videoRef.current;
      video.addEventListener('play', onPlay);
      video.addEventListener('pause', onPauseHandler);
      video.addEventListener('ended', onPauseHandler);
      video.addEventListener('timeupdate', onTimeUpdate);
      return () => {
        video.removeEventListener('play', onPlay);
        video.removeEventListener('pause', onPauseHandler);
        video.removeEventListener('ended', onPauseHandler);
        video.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, [readyToAttach, devices]);

  const onPlay = () => {
    setIsPlaying(true);
    getCurrentTime();
  };

  const onPauseHandler = () => {
    setIsPlaying(false);

    if (tvState.deviceSettings.sendActionsEnabled) {
      stopAllDevices().then();
    }
    reset();
  };

  const onTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime
    const now = performance.now();
    if (now - lastEmitTimeRef.current >= emissionIntervalMs) {
      const currentTimeMs = currentTime * 1000;
      checkTime(currentTimeMs);
      lastEmitTimeRef.current = now;
    }
  }

  return <>
    {
      isLoaded || !script ? <>
        {!isVR ?
          <div><video id="sceneVideo" ref={videoRef} controlsList="nodownload"
            playsInline={true}
            poster={posterUrl} controls style={{ width: "100%", height: "100%" }} onTimeUpdate={onTimeUpdate} onPlay={onPlay} onPause={onPauseHandler}>
            <source src={sourceUrl} />
          </video>
            {/* <button onClick={() => console.log(actions, getDevices())}>print</button>
            <button onClick={() => removeFunscript()}>remove fs</button>
            <button onClick={() => printDevices()}>devices</button> */}
            <h1></h1></div>
          :
          <dl8-video id="sceneVideo" ref={videoRef}
            format={`${vrType === 'none' ? 'STEREO_180_LR' : vrType}`}
            cors-fallback-url={`${constants.CONTENT_DOMAIN}vr?file=${sourceUrl}&${posterUrl}`}
            preferred-controls="MAGIC_WINDOW_CONTROLS"
            poster={posterUrl}>
            <dl8-action-button></dl8-action-button>
            <source src={sourceUrl} type="video/mp4" />
          </dl8-video>}</> : null}
  </>
};

export default VideoPlayerComponent;