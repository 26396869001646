import React, { useEffect, useState } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { Container } from 'react-bootstrap';

import constants from "../../const";
import ImageAlbumCover from "./image-album-cover";
import { helper } from "../common/helper";

export default function SimpleGallery(props) {

	var token = `?token=${localStorage.getItem('token').slice(7)}`;
	const [album, setAlbum] = "Pictures"
	const [pics, setPics] = useState({ contents: [], children: {} });
	const [readyToRender, setReadyToRender] = useState(false)

	useEffect(() => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: "#" + props.galleryID,
			children: "a",
			pswpModule: () => import("photoswipe"),
			counter: true,
			bgOpacity: 0.9,
			spacing: 0.5,
			wheelToZoom: true,
			zoom: true,
		});
		lightbox.init();

		return () => {
			lightbox.destroy();
			lightbox = null;
		};
	}, []);

	useEffect(() => {
		if (props.params.album == "Pictures" || props.params.album == null) {
			setPics(props.images)
			setReadyToRender(true)
		}
		else {
			setPics(props.images.children[props.params.album])
			setReadyToRender(true)
		}
	}, [props.params, props.images, pics])


	return (
		<><Container>
			{props.displayAlbum ? <>
				<h3>Albums</h3>
				<div className="row" style={{ maxWidth: `${window.innerWidth}px` }}>
					<ImageAlbumCover src={`${constants.CONTENT_DOMAIN}download/sus-media/galleries/${props.album}/preview.webp${token}`} link={`/gallery/${props.album}?album=Pictures`} key={'All'} gal={'All'} size={'medium'} />
					{Object.entries(props.images.children).map(([k]) => (
						<ImageAlbumCover src={`${constants.CONTENT_DOMAIN}download/sus-media/galleries/${props.album}/${k}/preview.webp${token}`} link={`/gallery/${props.album}?album=${k}`} key={k} gal={k} size={'medium'} />
					))}
				</div>
				</> : null}
			<h3>{props.params.album}</h3>
			<div className="pswp-gallery" id={props.galleryID}>
				{readyToRender ? <>{pics.contents.map((image) => (
					<>{image.isFile ? <a
						href={image.src}
						data-pswp-width={image.width}
						data-pswp-height={image.height}
						key={props.galleryID + '-' + image.key}
						target="_blank"
						rel="noreferrer"
					>
						<img
							src={image.src}
							alt="image"
							width={200}
							style={{ borderRadius: "5px", margin: "2px" }}
						/>
					</a> : null}</>
				))}</> : null}
			</div>
		</Container></>
	);
}
