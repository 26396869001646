import React, { useState, useEffect } from "react";
import { Row, CardDeck, Container } from "react-bootstrap";

import { fetchUserPlaylistsAPI } from "../../controller/userController.js";

import PlaylistCard from "../scenes/playlist-card.js";
import NewPlaylist from "../scenes/playlist-new-popup.js";

export default function Playlists(props) {
  const [playlists, setPlaylists] = useState([]);

  const fetchUserPlaylistsCallback = (data) =>{
    setPlaylists(data);
  }

  const refreshPlaylist = () =>{
    fetchUserPlaylistsAPI(fetchUserPlaylistsCallback);
  }

  useEffect(() => {
    refreshPlaylist();
  }, []);

  var playlistCardElement = [];

  var i = 0;
  if (Array.isArray(playlists)) {
    var colSize = 1;
    for (var col = 0; col < Math.ceil(playlists.length / colSize); col++) {
      var cardDeck = [];
      for (var row = 0; row < colSize; row++) {
        if (i < playlists.length) {
          if (playlists[i] !== null)
            cardDeck.push(
              <PlaylistCard
                key={`${row}-${col}`}
                style={{ width: "200px" }}
                playlist={playlists[i]}
                roles={props.perms}
                settings={{
                  "menu":{
                    addToFavorite: false,
                    removeFromPlaylist: false,
                    editPlaylist: true,
                    deletePlaylist: true
                  },
                  playlistInfo: playlists[i]
                }}
                refresh={refreshPlaylist}
              />
            );
          i++;
        }
      }
      playlistCardElement.push(
        <CardDeck key={col} style={{ padding: "10px" }}>
          {cardDeck}
        </CardDeck>
      );
    }
  }

  return (
    <>
      <Container fluid>
        <Row className="justify-content-center p-2">
          <h1>My Playlists ({playlists.length})</h1>
        </Row>
        <Row>{playlistCardElement}</Row>
        <NewPlaylist refresh={refreshPlaylist} buttonType={'fixedButton'}/>
      </Container>
    </>
  );
}
