import React, { useState, useEffect } from "react";
import { Row, Container } from "react-bootstrap";

import ScenesGalleryViewer from "./scenes-gallery-viewer.js";
import { fetchPlaylistByIdAPI } from "../../controller/userController.js";
import MultiPlayer from "./multi-player.js";

export default function PlaylistView(props) {
  const [playlists, setPlaylists] = useState([]);
  const [scenes, setScenes] = useState([]);

  const fetchPlaylistCallback = (data) =>{
    setPlaylists(data);
    formatScenes(data.scenes);
  }

  const refresh =() =>{
    fetchPlaylistByIdAPI(props.match.params.id, fetchPlaylistCallback);
  }

  useEffect(() => {
    fetchPlaylistByIdAPI(props.match.params.id, fetchPlaylistCallback);
  }, []);


  const formatScenes = (plScenes) => {
    var sclist = [];
    for (var s in plScenes) {
      sclist.push(plScenes[s].scene)
    }
    setScenes(sclist)
  }

  return (
    <Container fluid>
      <Row className="justify-content-center p-2">
        <h1>Playlist - {playlists.title}</h1>
      </Row>
      <Row>
        <ScenesGalleryViewer scenes={scenes} refresh={refresh} settings={{
          "menu": {
            addToFavorite: true,
            removeFromPlaylist: true
          },
          "pid": props.match.params.id
        }} />
      </Row>
      <Row>
        <MultiPlayer scenes={scenes}/>
      </Row>
    </Container>
  );
}
