// src/services/useButtplugService.js
import { createContext,useContext, useState, useEffect, useCallback } from 'react';
import {
  ButtplugBrowserWebsocketClientConnector,
  ButtplugClient,
  ButtplugClientDevice,
  ButtplugDeviceError,
  ButtplugError,
} from 'buttplug';
import { ButtplugWasmClientConnector } from 'buttplug-wasm/dist/buttplug-wasm.mjs';
import { FunscriptActionProvider } from './FunscriptActionService';

export const CLIENT_NAME = '66movies';

export const ButtplugConnectionState = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
};

const ButtplugServiceContext = createContext(null);

export const ButtplugServiceProvider = ({ children }) =>{
  const [client, setClient] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(undefined);
  const [connectionState, setConnectionState] = useState(ButtplugConnectionState.DISCONNECTED);
  const [scanning, setScanning] = useState(false);
  const [devices, setDevices] = useState([]);

  const getCurrentConnectionUrl = () => currentUrl;

  const getDevices = () => devices;

  const connectToExternalServer = useCallback(async (url) => {
    disconnect();
    setCurrentUrl(url);
    setConnectionState(ButtplugConnectionState.CONNECTING);

    const newClient = new ButtplugClient(CLIENT_NAME);
    setupClientListeners(newClient);

    const connector = new ButtplugBrowserWebsocketClientConnector(url);
    try {
      await newClient.connect(connector);
      setClient(newClient);
      setConnectionState(ButtplugConnectionState.CONNECTED);
      console.log('Connected to external server:', url);
    } catch (e) {
      console.error('Failed to connect to external server:', e);
      setConnectionState(ButtplugConnectionState.DISCONNECTED);
      throw e;
    }
  }, []);

  const connectToLocalServer = useCallback(async () => {
    disconnect();
    setCurrentUrl('In-Browser Server');
    setConnectionState(ButtplugConnectionState.CONNECTING);

    const newClient = new ButtplugClient(CLIENT_NAME);
    setupClientListeners(newClient);

    try {
      const connector = new ButtplugWasmClientConnector();
      await newClient.connect(connector);
      setClient(newClient);
      setConnectionState(ButtplugConnectionState.CONNECTED);
    } catch (e) {
      console.error('Failed to connect to local WASM server:', e);
      setConnectionState(ButtplugConnectionState.DISCONNECTED);
      throw e;
    }
  }, []);

  const disconnect = useCallback(async () => {
    console.log("Disconnecting from server");
    if (client && client.connected) {
      try {
        await client.disconnect();
      } catch (e) {
        console.warn('Error during disconnect:', e);
      }
    }
    cleanupClient();
    setConnectionState(ButtplugConnectionState.DISCONNECTED);
  }, [client]);

  const startScanning = useCallback(async () => {
    if (!isConnected()) {
      throw new Error('Cannot start scanning: Not connected to a server');
    }
    setScanning(true);
    await client.startScanning();
  }, [client]);

  const stopScanning = useCallback(async () => {
    if (!isConnected()) return;
    await client.stopScanning();
    setScanning(false);
  }, [client]);

  const vibrateDevice = useCallback(async (index, speed) => {
    const device = getDeviceByIndex(index);
    if (!device) throw new Error(`No device with index ${index}`);
    if (!device.canVibrate) throw new Error('Device does not support vibrate');

    try {
      const bpDevice = getClientDevice(index);
      await bpDevice.vibrate(speed);
    } catch (e) {
      handleDeviceCommandError(e, 'vibrate');
    }
  }, [client]);

  const linearDevice = useCallback(async (index, position, duration) => {
    const device = getDeviceByIndex(index);
    if (!device) throw new Error(`No device with index ${index}`);
    if (!device.canLinear) throw new Error('Device does not support linear');

    try {
      const bpDevice = getClientDevice(index);
      await bpDevice.linear(position, duration);
    } catch (e) {
      handleDeviceCommandError(e, 'linear');
    }
  }, [client]);

  const rotateDevice = useCallback(async (index, speed, clockwise) => {
    const device = getDeviceByIndex(index);
    if (!device) throw new Error(`No device with index ${index}`);
    if (!device.canRotate) throw new Error('Device does not support rotate');

    try {
      const bpDevice = getClientDevice(index);
      await bpDevice.rotate(speed, clockwise);
    } catch (e) {
      handleDeviceCommandError(e, 'rotate');
    }
  }, [client]);

  const stopDevice = useCallback(async (index) => {
    const bpDevice = getClientDevice(index);
    const device = getDeviceByIndex(index);

    // message.loading('Stopping your device. It may lightly move before stopping.', { duration: 1.5 });

    if (device) {
      try {
        if (device.canVibrate) await bpDevice.vibrate(0.01);
        if (device.canRotate) await bpDevice.rotate(0.01, true);
        if (device.canLinear) await bpDevice.linear(0.01, 500);
      } catch (e) {
        console.error(`Error sending gentle command before stop:`, e);
      }
    }

    setTimeout(async () => await bpDevice.stop().catch((e) => console.warn(`Stop command error:`, e)), 1000);
  }, [client]);

  const stopAllDevices = useCallback(async () => {
    if (!client) return;

    // message.loading('Stopping all devices. They may lightly move before stopping.', { duration: 1.5 });

    for (const bpDevice of client.devices) {
      const deviceInfo = getDeviceByIndex(bpDevice.index);
      if (!deviceInfo) continue;

      try {
        if (deviceInfo.canVibrate) await bpDevice.vibrate(0.01);
        if (deviceInfo.canRotate) await bpDevice.rotate(0.01, true);
        if (deviceInfo.canLinear) await bpDevice.linear(0.01, 500);
      } catch (e) {
        console.error(`Error sending gentle command before stopAll:`, e);
      }

      setTimeout(async () => await bpDevice.stop().catch((e) => console.warn(`StopAll command error:`, e)), 1000);
    }
  }, [client]);

  const isConnected = useCallback(() => connectionState === ButtplugConnectionState.CONNECTED, [connectionState]);

  const setupClientListeners = (clientInstance) => {
    clientInstance.addListener('deviceadded', handleDeviceAdded);
    clientInstance.addListener('deviceremoved', handleDeviceRemoved);
    clientInstance.addListener('scanningfinished', handleScanningFinished);
    clientInstance.addListener('disconnect', handleDisconnected);
  };

  const cleanupClient = () => {
    if (!client) return;
    client.removeAllListeners();
    setClient(null);
    setDevices([]);
    setScanning(false);
  };

  const handleDeviceAdded = (device) => {
    const newDevice = {
      index: device.index,
      name: device.name,
      canVibrate: device.vibrateAttributes.length > 0,
      canLinear: device.messageAttributes.LinearCmd !== undefined,
      canRotate: device.messageAttributes.RotateCmd !== undefined,
    };
    setDevices((prevDevices) => [...prevDevices, newDevice]);
  };

  const handleDeviceRemoved = (device) => {
    setDevices((prevDevices) => prevDevices.filter((d) => d.index !== device.index));
  };

  const handleScanningFinished = () => setScanning(false);

  const handleDisconnected = () => {
    cleanupClient();
    setConnectionState(ButtplugConnectionState.DISCONNECTED);
  };

  const getDeviceByIndex = (index) => devices.find((d) => d.index === index);

  const getClientDevice = (index) => {
    if (!client) throw new Error('Client not connected');
    const device = client.devices.find((d) => d.index === index);
    if (!device) throw new Error(`No device with index ${index} found on client`);
    return device;
  };

  const handleDeviceCommandError = (e, command) => {
    if (e instanceof ButtplugDeviceError) {
      console.error(`Device error on ${command}:`, e.message);
    } else if (e instanceof ButtplugError) {
      console.error(`Buttplug error on ${command}:`, e.message);
    } else {
      console.error(`Unknown error on ${command}:`, e);
    }
  };

  return (
    <ButtplugServiceContext.Provider
      value={{
        connectionState,
        scanning,
        devices,
        connectToExternalServer,
        connectToLocalServer,
        disconnect,
        startScanning,
        stopScanning,
        vibrateDevice,
        linearDevice,
        rotateDevice,
        stopDevice,
        stopAllDevices,
        getCurrentConnectionUrl,
        getDevices,
      }}
    >
      {children}
    </ButtplugServiceContext.Provider>
  );
};

export const useButtplugService = () => {
  return useContext(ButtplugServiceContext);
};
