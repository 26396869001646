import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Row, CardDeck, Container } from "react-bootstrap";
import SceneCard from '../scenes/scene-card';

function ScenesGalleryViewer(props) {

    var scenes = props.scenes;
    var sceneCardElement = [];
    var i = 0;
    if (Array.isArray(scenes)) {
        var colSize = 1;
        for (var col = 0; col < Math.ceil(scenes.length / colSize); col++) {
            var cardDeck = [];
            for (var row = 0; row < colSize; row++) {
                if (i < scenes.length) {
                    if (scenes[i] !== null) {
                        // var set = props.settings;
                        // set.sceneId= scenes[i].scene.id;
                        cardDeck.push(<SceneCard key={`${row}-${col}`} style={{ width: "200px" }} scene={scenes[i]} roles={props.perms} settings={props.settings} refresh={props.refresh} />);
                    }
                    i++;
                }
            }
            sceneCardElement.push(<CardDeck key={col} style={{ padding: "10px" }}>{cardDeck}</CardDeck>);
        }
    }

    return (
        <Container fluid>
            <Row className="justify-content-center p-2">
                <h1>Scenes {props.scenes?.length}</h1>
            </Row>
            <Row className="justify-content-center">
                {sceneCardElement}
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        perms: state.users.permissions.perms
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenesGalleryViewer);
