import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from './user/PrivateRoute';

import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

import { loginActions } from '../actions/user/loginActions';
import './App.css';
import Header from './header';

import Login from './user/login';
import Signup from './user/signup';
import ForgotPassword from './user/forgotPassword';
import Profile from './user/profile';
import Admin from './user/admin';

import Movies from './tv/Movies';
import Shows from './tv/Shows';

import Scenes from './scenes/scenes';
import SceneView from './scenes/scene-view';
import Actors from './actors/actors';
import ActorView from './actors/actor-view';
import MediaPlayer from './tv/MediaPlayer';

import { TvContext } from '../context/TvContext';
import { SocketContext, socke } from '../context/SocketContext';
import { UserContext } from '../context/UserContext';
import ResetPassword from './user/resetPassword';
import MapHome from './maps/map-home';
import CMSShows from './user/cmsShows';
import CMSMovies from './user/cmsMovies';
import GalleryView from './gallery/gallery-view';
import ActorGallery from './actors/actor-gallery';
import PlaylistView from './scenes/playlist-view';
import DeviceSettings from './funscript/DeviceSettings';
import { ButtplugServiceProvider } from './funscript/useButtplugService';
import ContentRequest from './tv/ContentRequest';
import { FunscriptActionProvider } from './funscript/FunscriptActionService';

setChonkyDefaults({ iconComponent: ChonkyIconFA });

function App(props) {

  const { getIp, authenticate } = props;

  const [tvState, setTvState] = useState({
    show: {
      showSeason: false,
      selectedSeason: {},
      selectedShowId: {}
    },
    move: {
      showMovie: false,
      selectedMovie: {}
    },
    groupChat: {
      active: false,
      sessionConnected: false,
      sessionId: ''
    },
    media: {
      playing: false
    },
    contentRequest: {
      active: false
    },
    deviceSettings: {
      active: false,
      serverUrl: 'ws://localhost:12345',
      sendActionsEnabled: true,
      scriptTimingOffsetMs: -200,
      preferences: {
        enabled: true,
        useVibrate: false,
        useRotate: false,
        useLinear: true
      }
    }

  })

  const [userState, setUserState] = useState({
    mobileEdit: false
  })

  useEffect(() => {
    // getIp();
    authenticate();
  }, [getIp, authenticate]);



  // const [socket, setSocket] = useState(socke);

  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      <TvContext.Provider value={{ tvState, setTvState }}>
        <ButtplugServiceProvider>
          <FunscriptActionProvider>
          {/* <SocketContext.Provider value={{ socket, setSocket }}> */}
          <Router>
            <Header />
            <ContentRequest />
            <DeviceSettings />
            <Switch>
              <Route exact path="/" component={Shows} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/forgot" component={ForgotPassword} />
              <Route path="/reset" component={ResetPassword} />
              <Route path="/movies" component={Movies} />
              <Route path="/shows" component={Shows} />
              <Route path="/player" component={MediaPlayer} />
              <Route path="/map" component={MapHome} />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 3)} component={Scenes} path="/scenes" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 3)} component={SceneView} path="/scene/:id" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 3)} component={Actors} path="/actors" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 3)} component={ActorView} path="/actor/:id" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 3)} component={ActorGallery} path="/galleries" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 3)} component={GalleryView} path="/gallery/:id" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 3)} component={PlaylistView} path="/playlist/:id" />
              {/* <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 8)} component={Profile} path="/profile" /> */}
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 8)} component={Profile} path="/profile/:id" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 1)} component={Admin} path="/admin" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 1)} component={CMSShows} path="/cms-shows" />
              <PrivateRoute authenticated={props.authorized} perm={props.perms.some(e => e.role.id === 1)} component={CMSMovies} path="/cms-movies" />
            </Switch>
          </Router>
          {/* </SocketContext.Provider> */}
          </FunscriptActionProvider>
        </ButtplugServiceProvider>
      </TvContext.Provider >
    </UserContext.Provider>
  )
}
const mapStateToProps = (state) => {
  return {
    // roleFetching: state.users.permissions.isFetching,
    authorized: state.users.user.authorized,
    perms: state.users.permissions.perms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: () => dispatch(loginActions.authenticate()),
    getIp: () => dispatch(loginActions.updateUserIp())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);