import axios from 'axios';
import constants from '../const.js';


const getActorById = async (id, callback) => {
    return axios.get(`${constants.DATA_API.ACTOR_URL}/${id}`)
        .then(res => {
            callback(res.data)
        }).catch(e => console.log(e));
}

const getActorTags = async (id, callback) =>{
    return axios.get(`${constants.DATA1_API_URL}/actor/${id}/tags`)
    .then(res => {
        callback(res.data)
    }).catch(e => console.log(e));
}

const updateActorTagDate = async (actorId, tagId, start, end, callback) =>{
    return axios.post(`${constants.DATA1_API_URL}/actor/${actorId}/tags/${tagId}/s/${start}/e/${end}`,{},{
        headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
    }).then(res =>{
        callback(res)
    }
    )
    .catch(e => console.log(e));
}

export {
    getActorById,
    getActorTags, 
    updateActorTagDate
}