import axios from 'axios';
import constants from '../../const.js';

const SCENE_U_INFO_REQ = "SCENE_U_INFO_REQ";
const SCENE_U_INFO_SUC = "SCENE_U_INFO_SUC";
const SCENE_U_INFO_ERR = "SCENE_U_INFO_ERR";

const userDataReq =()=>{
    return{
        type: SCENE_U_INFO_REQ
    }
}

const userDataSucc = (result)=>{
    return{
        type: SCENE_U_INFO_SUC,
        res: result
    }
}

const userDataErr = (err)=>{
    return{
        type: SCENE_U_INFO_ERR,
        error: err
    }
}

const getUserData = (sceneId) => {
    return dispatch =>{
        dispatch(userDataReq());
        var token = localStorage.getItem('token');
        return axios.get(`${constants.USER_API.SCENE_USERINFO_URL}/${sceneId}`,{
            headers:{ 'Authorization' : `${token}` }
        }).then(res => {
            dispatch(userDataSucc(res.data));
        }).catch(err=>dispatch(userDataErr(err)));
    }
}

const FAV_REQ = "FAV_REQ"
const FAV_SUC= "FAV_SUC"
const FAV_ERR = "FAV_ERR"
const favoriteScene_REQ = () =>{
    return{
        type: FAV_REQ,
    }
}
const favoriteScene_SUC = (change) =>{
    return{
        type: FAV_SUC,
        enabled: change
    }
}
const favoriteScene_ERR = (err) =>{
    return{
        type: FAV_ERR,
        error: err
    }
}

const addFavoriteScene = (sceneId) =>{
    return dispatch =>{
        dispatch(favoriteScene_REQ());
        var token = localStorage.getItem('token');
        return axios.get(`${constants.USER_API.SCENE_FAVORITES_URL}/${sceneId}`,{
            headers:{ 'Authorization' : `${token}` }
        }).then(res => dispatch(favoriteScene_SUC(true)))
        .catch(err => dispatch(favoriteScene_ERR(err)));
    }
}

const removeFavoriteScene = (sceneId) =>{
    return dispatch =>{
        dispatch(favoriteScene_REQ());
        var token = localStorage.getItem('token');
        return axios.delete(`${constants.USER_API.SCENE_FAVORITES_URL}/${sceneId}`,{
            headers:{ 'Authorization' : `${token}` }
        }).then(res => dispatch(favoriteScene_SUC(false)))
        .catch(err=>dispatch(favoriteScene_ERR(err)));
    }
}


const HIST_REQ = "HIST_REQ"
const HIST_SUC = "HIST_SUC"
const HIST_ERR = "HIST_ERR"
const historyScene_REQ = () =>{
    return{
        type: HIST_REQ,
    }
}
const historyScene_SUC = (change) =>{
    return{
        type: HIST_SUC,
        enabled: change
    }
}
const historyScene_ERR = (err) =>{
    return{
        type: HIST_ERR,
        error: err
    }
}
const addHistoryScene = (sceneId, time) =>{
    return dispatch =>{
        dispatch(historyScene_REQ());
        return axios.post(`${constants.USER_API.SCENE_HISTORY_URL}/${sceneId}`,
        {time: time},{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` },
        }).then(dispatch(historyScene_SUC())).catch(err=>dispatch(historyScene_ERR(err)));
    }
}

//todo: remove history scenes

const LIKE_REQ = "LIKE_REQ"
const LIKE_SUC= "FAV_SUC"
const DISLIKE_SUC = "DISLIKE_SUC"
const LIKE_ERR = "FAV_ERR"
const likeScene_REQ = () =>{
    return{
        type: LIKE_REQ,
    }
}
const likeScene_SUC = (change) =>{
    return{
        type: LIKE_SUC,
        enabled: change
    }
}
const dislikeScene_SUC = (change) =>{
    return{
        type: DISLIKE_SUC,
        enabled: change
    }
}
const likeScene_ERR = (err) =>{
    return{
        type: LIKE_ERR,
        error: err
    }
}

const addLikeScene = (sceneId, value, boolVal) =>{
    return dispatch =>{
        dispatch(likeScene_REQ());
        var token = localStorage.getItem('token');
        return axios.get(`${constants.USER_API.SCENE_LIKE_URL}/${sceneId}`,{
            headers:{ 'Authorization' : `${token}` }
        }).then(res =>{ 
            if(value==='like')
                dispatch(likeScene_SUC(boolVal));
            else if(value ==='dislike')
                dispatch(dislikeScene_SUC(boolVal));
            dispatch(getUserData(sceneId));
        })
        .catch(err => dispatch(likeScene_ERR(err)));
    }
}


export const userDataActions = {
    SCENE_U_INFO_REQ,
    SCENE_U_INFO_SUC,
    SCENE_U_INFO_ERR,
    getUserData,

    FAV_REQ,
    FAV_SUC,
    FAV_ERR,
    addFavoriteScene,
    removeFavoriteScene,

    LIKE_REQ,
    LIKE_SUC,
    DISLIKE_SUC,
    LIKE_ERR,
    addLikeScene,
    
    HIST_REQ,
    HIST_SUC,
    HIST_ERR,
    addHistoryScene,
};
