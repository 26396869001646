import axios from 'axios';
import constants from '../../const.js';

const SCENE_FAV_REQUEST = "SCENE_FAV_REQUEST";
const SCENE_FAV_SUCCESS = "SCENE_FAV_SUCCESS";
const SCENE_FAV_FAILURE = "SCENE_FAV_FAILURE";

const sceneCardRequest = () =>{
    return{
        type: SCENE_FAV_REQUEST
    };
};

const sceneCardSuccess = (scenes) =>{
    var scenesList = []
    scenes.scenes.forEach(scene => {
        scenesList.push(scene.scene)
    });
    return{
        type: SCENE_FAV_SUCCESS,
        scenes: scenesList,
        // sites: scenes.sites,
        // models: scenes.models,
        // tags: scenes.tags,
        count: scenesList.length
    };
};

const sceneCardFailure = (error) =>{
    return{
        type: SCENE_FAV_FAILURE,
        error
    };
};

const getScenes = () =>{    
    return dispatch =>{
        dispatch(sceneCardRequest());
        return axios.get(constants.USER_API.SCENE_FAVORITES_URL,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        })
        .then(res => {
            dispatch(sceneCardSuccess(res.data));
        })
        .catch(error=> dispatch(sceneCardFailure(error)))
    };
};



export const userFavSceneActions = {
    SCENE_FAV_REQUEST,
    SCENE_FAV_SUCCESS,
    SCENE_FAV_FAILURE,
    getScenes
};

