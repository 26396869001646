import axios from 'axios';
import constants from '../const.js';
const getToken = () => {
    return localStorage.getItem('token').slice(7);
}
const uploadPreview = (file, id, callback) =>{
    var token = getToken();
    var data = new FormData();
    data.append('preview', file)
    return axios.post(`${constants.CONTENT_DOMAIN}upload/scene/${id}/preview?token=${token}`, data, {})
      .then(res => callback(false))
      .catch(e => console.log(e));
}

const uploadActorPoster = (file, id, callback) =>{
  var token = getToken();
  var data = new FormData();
  data.append('preview', file)
  return axios.post(`${constants.CONTENT_DOMAIN}upload/actor/${id}?token=${token}`, data, {})
    .then(res => callback(false))
    .catch(e => console.log(e));
}

/**
 * get directory of one scene
 * @param {*} file filename 
 * @param {*} id scene id
 * @param {*} callback callback function
 * @returns 
 */
const getDirectory = (file, id, callback) =>{
    var token = getToken();
    var data = new FormData();
    data.append('preview', file);
    return axios.get(`${constants.CONTENT_DOMAIN}files/directory/scenes/${id}?path=${file}&token=${token}`, data, {})
      .then(res => callback(res.data))
      .catch(e => console.log(e));
}

/**
 * get directory of one Actor
 * @param {*} file filename 
 * @param {*} id scene id
 * @param {*} callback callback function
 * @returns 
 */
 const getActorDirectory = (file, id, callback) =>{
  var token = getToken();
  var data = new FormData();
  data.append('preview', file);
  return axios.get(`${constants.CONTENT_DOMAIN}files/directory/actors/${id}?path=${file}&token=${token}`, data, {})
    .then(res => callback(res.data))
    .catch(e => console.log(e));
}

/**
 * Convert Video and creates trailer
 */
const convertVideo = () =>{

}

export {
  uploadPreview,
  uploadActorPoster,
  getDirectory,
  getActorDirectory,
  convertVideo}