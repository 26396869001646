import React, { useEffect, useState } from 'react';

import FavoriteHeart from '../common/favorite-heart';
import LikeThumb from '../common/like-thumb';
import DislikeThumb from '../common/dislike-thumb';

import { addSceneToDefaultPlaylistAPI, fetchSceneUserDataAPI, removeSceneFromDefaultAPI } from '../../controller/userController';
import ScenesDropDownMenu from '../scenes/ScenesDropDownMenu';

export default function SceneUserData(props) {

    const [userData, setUserData] = useState({
        favorite: false,
        like: false,
        dislike: false,
        time: 0
    })

    const callBack = (data) => {
        console.log(data)
    }

    const fetchSceneUserDataCallback = (data) => {
        setUserData(data)
    }

    useEffect(() => {
        fetchSceneUserDataAPI(props.sceneId, fetchSceneUserDataCallback);
    }, []);

    const favoriteClick = () => {
        if (userData.favorite) {
            removeSceneFromDefaultAPI(props.sceneId, 'favorite', callBack)
            setUserData({ ...userData, favorite: false })
        }
        else {
            addSceneToDefaultPlaylistAPI(props.sceneId, 'favorite', callBack)
            setUserData({ ...userData, favorite: true })
        }
    }

    const likeClick = () => {
        if (userData.like) {
            removeSceneFromDefaultAPI(props.sceneId, 'like', callBack)
            setUserData({ ...userData, like: false })
        }
        else {
            addSceneToDefaultPlaylistAPI(props.sceneId, 'like', callBack)
            setUserData({ ...userData, like: true })
        }
    }

    const dislikeClick = () => {
        if (userData.dislike) {
            removeSceneFromDefaultAPI(props.sceneId, 'dislike', callBack)
            setUserData({ ...userData, dislike: false })
        }
        else {
            addSceneToDefaultPlaylistAPI(props.sceneId, 'dislike', callBack)
            setUserData({ ...userData, dislike: true })
        }
    }

    return <div className="d-flex m-2">
        <FavoriteHeart fill={userData.favorite} clickEvent={favoriteClick} />
        <LikeThumb fill={userData.like} clickEvent={likeClick} />
        <DislikeThumb fill={userData.dislike} clickEvent={dislikeClick} />
        <div className='pl-3'>
            <ScenesDropDownMenu type={"scene-view"} sceneId={props.sceneId}/></div>
    </div>;
}