import axios from 'axios';
import constants from '../const.js';
const getToken = () => {
    return localStorage.getItem('token').slice(7);
}

const getBearerToken = () =>{
    return localStorage.getItem('token');
}


// USERS
const login = (email, password, callback) => {
    return axios.post(constants.USER_API.LOGIN_URL, {
        email: email,
        password: password
    }).then(res => {
        callback(res.data);
    }).catch(err => callback(err));
}


const getTags = (id, callback) => {
    return axios.get(`${constants.DATA_API.SCENE_URL}/${id}/tags`)
        .then(res => {
            callback(res.data)
        }).catch(e => console.log(e));
}


// PLAYLISTS
const fetchUserPlaylistsAPI = (callback) => {
    axios
        .get(constants.USER_API.SCENE_PLAYLIST_URL, {
            headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(res => callback(res.data)).catch(e => console.log(e));
}

const fetchPrivacyTypeAPI = (callback) => {
    axios
        .get(`${constants.USER_API.SCENE_PLAYLIST_URL}/privacy`, {
            headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(res => callback(res.data)).catch(e => console.log(e));
}

const createNewPlaylistAPI = (data, callback) => {
    axios
        .post(`${constants.USER_API.SCENE_PLAYLIST_URL}`, data,
            { headers: { Authorization: `${localStorage.getItem("token")}` } })
        .then(res => callback(res.data)).catch(e => console.log(e));
}

const updatePlaylistAPI = (data, callback) => {
    axios
        .put(`${constants.USER_API.SCENE_PLAYLIST_URL}/pid/${data.id}`, data, {
            headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(res => callback(res)).catch(e => console.log(e));
}


const deletePlaylistAPI = (id, callback) => {
    axios
        .delete(`${constants.USER_API.SCENE_PLAYLIST_URL}/pid/${id}`, {
            headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(res => callback(res)).catch(e => console.log(e));
}


const fetchPlaylistByIdAPI = (playlistId, callback) => {
    axios
        .get(`${constants.USER_API.SCENE_PLAYLIST_URL}/pid/${playlistId}`, {
            headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then((res) => callback(res.data)).catch(e => console.log(e));
}

const removeSceneFromPlaylistAPI = async (playlistId, sceneId, callback) => {
    await axios
        .delete(`${constants.USER_API.SCENE_PLAYLIST_URL}/pid/${playlistId}/${sceneId}`, {
            headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(res => callback(res)).catch(e => console.log(e))
}

const fetchPlaylistBySceneAPI = (sceneId, callback) => {
    axios
        .get(`${constants.USER_API.SCENE_PLAYLIST_URL}/scene/${sceneId}`, {
            headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(res => callback(res.data)).catch(e => console.log(e));
}

const addSceneToPlaylistAPI = async (playlistId, sceneId) => {
    await axios
        .post(`${constants.USER_API.SCENE_PLAYLIST_URL}/pid/${playlistId}/${sceneId}`, null,
            { headers: { Authorization: `${localStorage.getItem("token")}` } })
        .then(res => console.log(res)).catch(e => console.log(e));
}

// SCENE DEFAULT PLAYLIST
const fetchSceneUserDataAPI = async (sceneId, callback) =>{
    return await axios.get(`${constants.USER_API.SCENE_USERINFO_URL}/${sceneId}`,{
        headers:{ 'Authorization' : `${getBearerToken()}` }
    })
    .then(res => {
        callback(res.data)
    }).catch(e => console.log(e));
}


const addSceneToDefaultPlaylistAPI = async (sceneId, playlistType, callback) =>{
    return await axios.get(`${constants.USER_API_URL}/users/${playlistType}/scenes/${sceneId}`,{
        headers:{ 'Authorization' : `${getBearerToken()}` }
    })
    .then(res => { callback(res.data)}).catch(e => console.log(e));
}

const removeSceneFromDefaultAPI = async (sceneId, playlistType, callback) =>{
    return await axios.delete(`${constants.USER_API_URL}/users/${playlistType}/scenes/${sceneId}`,{
        headers:{ 'Authorization' : `${getBearerToken()}` }
    })
    .then(res => {callback(res.data)}).catch(e => console.log(e));
}


export {
    login,
    getTags,
    fetchUserPlaylistsAPI,
    fetchPrivacyTypeAPI,
    createNewPlaylistAPI, updatePlaylistAPI, deletePlaylistAPI,
    fetchPlaylistByIdAPI, removeSceneFromPlaylistAPI, fetchPlaylistBySceneAPI, addSceneToPlaylistAPI,
    fetchSceneUserDataAPI, addSceneToDefaultPlaylistAPI, removeSceneFromDefaultAPI
}