import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useButtplugService } from './useButtplugService.js';

import { TvContext } from '../../context/TvContext';
import constants from '../../const.js';
import { Modal, Button, Col, Row, Container, Form, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { helper } from '../common/helper';


function ButtplugControlPanel(props) {

    const {
        connectionState,
        devices,
        connectToExternalServer,
        connectToLocalServer,
        disconnect,
        startScanning,
        stopScanning,
    } = useButtplugService();
    const { tvState, setTvState } = useContext(TvContext);

    const hideModal = () => {
        setTvState({
            ...tvState,
            deviceSettings: {
                ...tvState.deviceSettings,
                active: false
            }
        })
    }

    const setServerUrl = (url) => {
        setTvState({
            ...tvState,
            deviceSettings: {
                ...tvState.deviceSettings,
                serverUrl: url
            }
        })
    }

    const validateUrl = (url) => {
        const pattern = /^wss?:\/\/[\w.-]+(:\d+)?(\/[\w.-]*)*$/;
        return pattern.test(url)
    }


    return (
        <Modal size="md" show={tvState.deviceSettings.active} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Device Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row><h5>Server Connection</h5></Row>
                    <Row>
                        <Form.Group as={Col} controlId="validationServerURL">
                            <Form.Label srOnly>Server URL</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Server URL</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="ws://localhost:12345" value={tvState.deviceSettings.serverUrl} onChange={(e) => setServerUrl(e.target.value)} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => connectToExternalServer(tvState.deviceSettings.serverUrl)} >Connect</Button>
                            <Button variant="secondary" className='m-2' onClick={disconnect}>Disconnect</Button>
                            {/* <Button variant="primary" onClick={() => connectToLocalServer()} >Connect to Local Server</Button> */}
                        </Col>
                    </Row>
                    <Row><Col><p>Connection State: {connectionState}</p></Col></Row>
                </Container>
                <Container>
                    <Row> <h5>Device Scanning</h5></Row>
                    <Col><p>Devices: {devices.length >0 ? devices[0].name: 'No Devices Connected'}</p></Col>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={startScanning} >Start Scanning</Button>
                            <Button variant="secondary" className='m-2' onClick={stopScanning}>Stop Scanning</Button>
                        </Col>
                    </Row>
                </Container>
                <Container>
                <Row> <h5>FunScript</h5></Row>
                <Row><p>script: {}</p></Row>
                </Container>
            </Modal.Body>

        </Modal>
    )
}

export default ButtplugControlPanel;