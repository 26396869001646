import React, { useState, useEffect } from "react";
import { Form, Col, InputGroup, Modal, Button } from "react-bootstrap";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-dropdown-select";

import { fetchPrivacyTypeAPI, createNewPlaylistAPI } from "../../controller/userController";

export default function NewPlaylist(props) {
  const [show, setShow] = useState(false);
  const [privacy, setPrivacy] = useState([]);
  const [playlistInfo, setPlaylistInfo] = useState({
    title: "",
    tags: [],
    privacy: 1,
  });

  const createPlaylistCallback = (data) => {
    props.refresh()
  }

  useEffect(() => {
    fetchPrivacyTypeAPI(setPrivacy)
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setShow(false);
  };

  const handleSave = () => {
    createNewPlaylistAPI(playlistInfo, createPlaylistCallback)
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>{props.buttonType == "fixedButton" ?
      <Fab size="small" color="primary" aria-label="add" style={{ position: "fixed", bottom: "30px", right: "30px" }}>
        <AddIcon onClick={handleShow} />
      </Fab> : null}

      {props.buttonType == "modalButton" ? <Button size="sm" onClick={handleShow}>Create Playlist</Button> : null}

      <Modal size="md" show={show} onHide={handleCancel} style={{ backgroundColor: "hsla(0, 0%, 0%, 0.9)" }}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Create a new Playlist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Group as={Col} md="10" controlId="validationTitle">
                <Form.Label srOnly>Title</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Title</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control required type="text" placeholder="" defaultValue={playlistInfo.title}
                    onChange={(e) =>
                      setPlaylistInfo({
                        ...playlistInfo,
                        title: e.target.value,
                      })} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a valid Title
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs="4" controlId="validationPrivacy">
                <Form.Label srOnly>Privacy</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Privacy</InputGroup.Text>
                  </InputGroup.Prepend>
                  <select className="custom-select custom-select-sm" value={playlistInfo.privacy}
                    onChange={(e) =>
                      setPlaylistInfo({ ...playlistInfo, privacy: parseInt(e.target.value) })
                    }>
                    {privacy.map((pv) => (
                      <option key={pv.privacy_id} value={pv.privacy_id}>
                        {pv.privacy_name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="validationTags">
                <Form.Label srOnly>Tags</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Tags</InputGroup.Text>
                    <Select style={{ minWidth: "260px" }} multi placeholder="+ add tag" values={[]}
                      color="#000000" searchable="true" clearable="true"
                      dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                      onChange={e => setPlaylistInfo({ ...playlistInfo, tags: [...playlistInfo.tags, e[e.length - 1].value] })} create="true" />
                  </InputGroup.Prepend>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a valid Title
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
