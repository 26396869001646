import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import LensIcon from '@mui/icons-material/Lens';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

import VideoPlayer from './video-player';
import constants from '../../const';

export default function MultiPlayer(props) {

    const [open, setOpen] = useState(false);
    const [play, setPlay] = useState(false);
    const [muted, setMuted] = useState(false);
    const [seek, setSeek] = useState(0);
    const [trailer, setTrailer] = useState(false);
    var isMobile = window.innerWidth <= constants.mobileSize;

    const openTrailer = () =>{
        if (trailer) {
            setTrailer(false);
        }
        else {
            setTrailer(true)
        }
    }
    const openPlayer = () =>{
        if (open) {
            setOpen(false);
        }
        else {
            setOpen(true)
        }
    }
    const playVid = () => {
        if (play) {
            setPlay(false);
        }
        else {
            setPlay(true)
        }
    }

    const muteVid = () => {
        if (muted) {
            setMuted(false)
        }
        else {
            setMuted(true)
        }
    }

    const seekVid = () => {
        setSeek(seek + 1)
    }

    // const preferred_ratio = 1.60;
    var num_cols = 1;

    const calc_cols_rows = (screenWidth, num_items) =>{

        if (isMobile){
            num_cols =2;
        }
        else{
           num_cols = Math.ceil(Math.sqrt(num_items));
        }
    }


    return <><Button style={{ right: "70px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => openPlayer()}><VideocamIcon /></Button>
    
    {open?
    <div style={{ "width": window.innerWidth, "height": window.innerHeight }} className='flex-container'>
        {open?<>
        {calc_cols_rows(window.innerWidth, props.scenes.length)}
        <Button style={{ right: "260px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => openTrailer()}>{trailer?<PanoramaFishEyeIcon/>: <LensIcon/>}</Button>
        <Button style={{ right: "200px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() =>openPlayer()}>{open ? <VideocamOffIcon /> : null}</Button>
        <Button style={{ right: "140px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => playVid()}>{play ? <PauseIcon /> : <PlayArrowIcon />}</Button>
        <Button style={{ right: "70px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => muteVid()}>{muted ? <VolumeOffIcon /> : <VolumeUpIcon />}</Button>
        <Button style={{ right: "10px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => seekVid()}><FastForwardIcon /></Button>
       

        </>:null}
            
        {open?<div>
            {props.scenes.map(scene => (
                <VideoPlayer scene={scene} play={play} muted={muted} seek={seek} width={window.innerWidth/num_cols} trailer={trailer}/>))}
        </div>:null}
    </div>:null}
    </>
}