import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import { Container, Row, Col, CardDeck, Button, Input } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import dayjs from 'dayjs';

import '../App.css';

import { sceneActions } from '../../actions/scene/sceneActions.js';
import { userDataActions } from '../../actions/scene/userDataActions';
import SpinnerLoad from '../common/spinner';

import constants, { TAGS } from '../../const';
import SceneCard from '../scenes/scene-card';
import UploadFile from '../common/upload-file1';
import SceneUserData from '../user/scene-user-data';
import EditScene from '../scenes/edit-scene-info';
import { helper } from '../common/helper';
import PositionAdder from './position-adder.js';
import SimpleGallery from '../gallery/SimpleGallery.js';
import ImageGalleryView from '../gallery/image-gallery-view.js';
import DeviceSettings from '../funscript/DeviceSettings.js';
import FunscriptHeatmapComponent from '../funscript/funscriptHeatmapComponent.js';
import VideoPlayerComponent from '../funscript/VideoPlayerComponent.js';
import { useButtplugService } from '../funscript/useButtplugService.js';
import { useFunscriptAction } from '../funscript/FunscriptActionService.js';
import { TvContext } from '../../context/TvContext.js';


function SceneView(props) {
    var utc = require('dayjs/plugin/utc')
    dayjs.extend(utc)

    const { tvState, setTvState } = useContext(TvContext);

    const { getScene, getSceneTag } = props;
    const [openPositions, setOpenPositions] = useState(true);
    const [scenePositions, setScenePositions] = useState([]);
    const [funscript, setFunscript] = useState({});
    const [errorFlag, setErrorFlag] = useState(false);
    const [tab, setTab] = useState(0);

    // 0 - Scene
    // 1 - Gallery


    const {
        connectionState,
        devices,
        connectToExternalServer,
        connectToLocalServer,
        disconnect,
        startScanning,
        stopScanning,
        getDevices,
    } = useButtplugService();

    const {
        loadFunscript,

        findActionIndexForTime,
        dispatchAction,
        reset,
        checkTime,
        funscriptDuration,
        currentFunscript, actions } = useFunscriptAction();


    const vidRef = useRef(null)
    var token = `?token=${localStorage.getItem('token').slice(7)}`;

    const fetchScenePositions = () => {
        axios.get(`${constants.DATA1_API_URL}/scene/${parseInt(props.match.params.id)}/positions`).then((res) => {
            setScenePositions(res.data)

        })
    }

    const fetchFunscript = async () => {
        await axios.get(`${constants.CONTENT_DOMAIN}dload/${props.scene.scene.scene.videoUrl?.replace('stream/', '').replace('.mp4', '.funscript')}${token}`)
            .then((res) => {
                setFunscript(res.data)
                loadFunscript(res.data)
            })
    }

    const printFunscript = () => {
        console.log('actions from Scene',actions);
        console.log('Devices from scene', getDevices());
    }


    const collapseFunc = () => {
        if (openPositions === true)
            setOpenPositions(false);
        else
            setOpenPositions(true);
    }

    useEffect(async () => {
        fetchScenePositions();
        getScene(parseInt(props.match.params.id));
        // await fetchFunscript()
        getSceneTag(parseInt(props.match.params.id));
        // eslint-disable-next-line
    }, [props.match.params.id]);



    useEffect(() => {
        getCurrentTime();
        // fetchFunscript();
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        const script = document.createElement('script');

        script.src = "//cdn.delight-vr.com/latest/dl8-d64cee8d26e5269fea818f00483604884ade3832.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const category = ["Scene", "Role", "Location", "Object", "Outfit"];

    const updateErrorFlag = () => {
        setErrorFlag(true);
        props.flagScene(scene.scene.id)
        props.getScene(scene.scene.id);
    }
    const getCurrentTime = () => {
        var vid = document.getElementById("sceneVideo");
        props.addToHistory(parseInt(props.match.params.id), vid.currentTime);
    };

    const jumpVideo = (value) => {
        vidRef.currentTime = value;
        var vid = document.getElementById(`sceneVideo`);
        vid.currentTime = value;
    }


    const RelatedScenes = () => {
        var relatedScenes = [];
        if (props.relScenes.movie.count > 0) {
            var movieSceneCard = [];
            props.relScenes.movie.scenes.forEach(scene => {
                movieSceneCard.push(<CardDeck key={scene.scene.id} className="relcard"><SceneCard scene={scene} /></CardDeck>);
            });
            relatedScenes.push(
                <Container key="movie-0" style={{ maxWidth: `${window.innerWidth}px`, overflowY: 'auto' }}>
                    <Row className="justify-content-center"><h3>{`${props.relScenes.movie.count} Scenes with ${props.scene.movie.title}`}</h3></Row>
                    <Row> <div className="scrollcards">{movieSceneCard}</div></Row>
                </Container>);
        }
        if (props.relScenes.models.length > 0) {
            var relScenes = [];
            props.relScenes.models.forEach(model => {
                var modelSceneCard = [];
                model.scenes.forEach(scene => {
                    modelSceneCard.push(<CardDeck key={scene.scene.id} className="relcard"><SceneCard scene={scene} /></CardDeck>);
                });
                relScenes.push(
                    <div key={`model-${model.models[0].name}`} style={{ maxWidth: `${window.innerWidth}px`, backgroundColor: "#787878" }} className="container">
                        <Row className="justify-content-center"><h3>{`${model.count} Scenes with ${model.models[0].name}`}</h3></Row>
                        <div className="row">
                            <div className="scrollcards">{modelSceneCard}</div>
                        </div>
                    </div>)

            });
            relatedScenes.push(relScenes);
        }
        if (props.relScenes.site.count > 0) {
            var siteSceneCard = [];
            props.relScenes.site.scenes.forEach(scene => {
                siteSceneCard.push(<CardDeck key={scene.scene.id} className="relcard"><SceneCard scene={scene} /></CardDeck>);
            });
            relatedScenes.push(
                <Container key="site-0" style={{ maxWidth: `${window.innerWidth}px` }}>
                    <Row className="justify-content-center"><h3>{`${props.relScenes.site.count} Scenes with ${props.scene.site.name}`}</h3></Row>
                    <Row><div className="scrollcards">{siteSceneCard}</div></Row>
                </Container>);
        }
        return relatedScenes
    }



    if (!props.isFetching) {
        var scene = props.scene.scene;
        document.title = `${scene.scene.id}|${scene.scene.title}`;
    }


    return (<>
        {!props.isFetching ? <Container style={{ maxWidth: `${window.innerWidth}px` }}>
            {scene.scene.id === 0 && props.error !== '' ? <Redirect to='/scenes'></Redirect> : <></>}
            <Container>
                <Row style={{ marginTop: "50px" }} className="justify-content-between">
                    <h2>{props.scene.scene.scene.title}</h2>
                    <SceneUserData sceneId={props.match.params.id}></SceneUserData>
                </Row>
                <Row>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 0 ? 'active' : ''}`} onClick={() => setTab(0)}>Scene</div>
                        </li>
                        {props.scene.scene.scene.gallery ?
                            <li className="nav-item">
                                <div className={`nav-link ${tab == 1 ? 'active' : ''}`} onClick={() => setTab(1)}>Gallery</div>
                            </li> : null}
                    </ul>
                </Row>
                {tab == 0 ?
                    <Row className="justify-content-between">
                        <div style={{ width: "100%", height: "100%", }}>
                            {!props.isFetchingSceneTag ?
                                <VideoPlayerComponent id="sceneVideo" ref={vidRef}
                                    sourceUrl={`${constants.CONTENT_DOMAIN}${props.scene.scene.scene.videoUrl}${token}#t=${props.startTime}`}
                                    posterUrl={`${constants.CONTENT_DOMAIN}${props.scene.scene.scene.posterUrl}${token}`}
                                    isVR={props.sceneTag.some(e => e.id === "469") || props.sceneTag.some(e => e.id === "459")}
                                    vrType={props.scene.scene.scene.vrType}
                                    script={props.sceneTag.some(e => e.id === "649")}
                                    getCurrentTime={getCurrentTime}
                                /> : null}

                            {props.sceneTag.some(e => e.id === "649") ? <>{!props.isFetchingUserInfo & !props.isFetching ? (
                                <FunscriptHeatmapComponent funscriptUrl={`${constants.CONTENT_DOMAIN}dload/${props.scene.scene.scene.videoUrl?.replace('stream/', '').replace('.mp4', '.funscript')}${token}`} widthPx={'100%'} heightPx={'75px'} />) : null}
                                <input defaultValue={tvState.deviceSettings.scriptTimingOffsetMs} 
                                onChange={(e)=>setTvState({...tvState,
                                    deviceSettings: {
                                        ...tvState.deviceSettings,
                                        scriptTimingOffsetMs: parseInt(e.target.value)
                                    }
                                })}/>
                                <Button onClick={fetchFunscript}>Refresh Funscript</Button>
                                <Button onClick={()=>{printFunscript(); console.log(tvState.deviceSettings)}}>Print Funscript</Button></> : null}
                            {/* {!props.isFetchingUserInfo & !props.isFetching ? (videoHTML) : ""} */}
                        </div>
                    </Row> : null}




                {tab == 1 && props.scene.scene.scene.gallery ?
                    <Row>
                        {/* <SimpleGallery galleryID={"psp-gallery"} album={"Solo%20-%20You're%20a%20pain%20in%20the%20back!"} images={gallery} params={props.params}/> */}
                        <ImageGalleryView id={props.scene.scene.scene.gallery.split('/')[0]} params={{
                            album: props.scene.scene.scene.gallery.split('/')[1],
                            displayAlbum: false
                        }} />
                    </Row> : null}
                {scenePositions.length > 0 ?
                    <Row>
                        {openPositions ? <PositionAdder sceneId={props.match.params.id} jumpVideo={jumpVideo} scenePositions={scenePositions} /> : null}
                    </Row> : null}
                <Row>
                    <Col sm={3} md={4} style={{ marginLeft: "20px" }}>
                        <Row style={{ marginTop: "10px" }}>
                            {props.scene.actors.map((actor, index) =>
                                <Link key={index} to={`/actor/${actor.id}`}><span style={{ fontSize: "16px", marginRight: "5px" }}>{actor.name}{index == props.scene.actors.length - 1 ? null : ', '} </span></Link>
                            )}
                        </Row>
                        <Row><div className="text-muted" style={{ fontSize: "15px" }}>{dayjs(scene.scene.date).format("D-MMM-YYYY")}</div></Row>
                        <Row className="">
                            <a href={`/scenes?site=${props.scene.site.siteId}`} className="site site-scene">
                                <div className="site-left-box site">{props.scene.site.shortname}</div>
                                <div className="site-right-box site">{props.scene.site.name}</div>
                            </a>
                            {props.scene.movie.id !== 0 ?
                                <a href={`/scenes?movie=${props.scene.movie.id}`} className="site site-scene">
                                    <div className="site-left-box site">{props.scene.movie.scenesNumber}</div>
                                    <div className="site-right-box site">{props.scene.movie.title}</div>
                                </a> : <div />}
                        </Row>
                    </Col>
                    <Col>
                        <Row className="justify-content-between">
                            <Col><h3>Tags</h3></Col>
                            {props.perms.some(e => e.role.id === 12) ? <Col><UploadFile sceneId={props.scene.scene.scene.id} /></Col> : null}
                            {props.perms.some(e => e.role.id === 2) ? <Col><EditScene sceneView={true} sceneId={scene.scene.id} /></Col> : null}
                        </Row>
                        {!props.isFetchingSceneTag ? category.map((cat, idx) => (
                            <Row key={`${cat}${idx}`}>
                                <Col xs={3} sm={3} md={2}>{cat}</Col>
                                <Col>
                                    <Row>
                                        {helper.findTag(TAGS.SCENECATEGORY, cat, props.sceneTag).map((tag) =>
                                        (<div key={tag.id} className="m-1 badge badge-secondary">
                                            <span> <Link style={{ color: "white" }} to={`/scenes?tag=${tag.id}`}>{tag.name}</Link> </span>
                                        </div>))}
                                    </Row>
                                </Col>
                            </Row>)) : <div><SpinnerLoad></SpinnerLoad></div>}
                    </Col>
                    {scenePositions.length > 0 ?
                        <Col md={1}>
                            <Row>
                                <button className="btn btn-secondary dropdown-toggle" onClick={collapseFunc}>Positions</button>
                            </Row>
                        </Col> : null}
                </Row>
                <Row>
                    <div className="text-left mt-4" style={{ fontSize: "16px" }}>{props.scene.scene.scene.description}</div>
                </Row>
                <Row>
                    <a className={`btn btn-sm m-2 btn-info`} href={`${constants.CONTENT_DOMAIN}dload/${scene.scene.videoUrl?.replace('stream/', '')}${token}`} onClick={() => props.downloadHistory(scene.scene.id)}>Download</a>
                    {props.sceneTag.some(e => e.id === "649") ? <a className={`btn btn-sm m-2 btn-info`} href={`${constants.CONTENT_DOMAIN}dload/${scene.scene.videoUrl?.replace('stream/', '').replace('.mp4', '.funscript')}${token}`} onClick={() => props.downloadHistory(scene.scene.id)}>FunScript</a> : null}
                    {scene.scene.statusName !== 'COM' & scene.scene.statusName !== 'UE' ? <div className={`badge-pill btn-sm m-2 badge-${scene.scene.statusName === 'REF' | scene.scene.statusName === 'IPI' ? 'warning' : 'danger'}`}>{scene.scene.statusName === 'REF' | scene.scene.statusName === 'IPI' ? 'Partial metadata' : 'Missing'}</div> : null}
                    {scene.scene.statusName !== 'UE' ?
                        <button type="button" className={`btn btn-sm m-2 btn-danger`} onClick={updateErrorFlag}>Flag Missing Video</button> : <div className={`badge-pill btn-sm m-2 badge-danger`}>Missing Video, Already Flagged!</div>}
                </Row>
                <Row><RelatedScenes /></Row>
            </Container>

        </Container> : <SpinnerLoad></SpinnerLoad>}
    </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.sceneReducers.isFetching,
        scene: state.sceneReducers.scene,
        error: state.sceneReducers.error,

        isFetchingSceneTag: state.sceneReducers.isFetchingSceneTag,
        sceneTag: state.sceneReducers.sceneTag,
        tags: state.sceneReducers.tags,

        relScenes: state.sceneReducers.relatedScenes,

        allStatus: state.scenes.status.allStatus,
        startTime: state.scenes.userData.history.time,
        perms: state.users.permissions.perms
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getScene: (id) => dispatch(sceneActions.getScene(id)),
        getSceneTag: (id) => dispatch(sceneActions.getSceneTag(id)),
        getRelatedScene: ((site, model, movie) => dispatch(sceneActions.getRelatedSceneCard(10, 1, site, "date", "desc", model, "", movie))),
        addToHistory: (id, time) => dispatch(userDataActions.addHistoryScene(id, time)),
        downloadHistory: (id) => dispatch(sceneActions.downloadSceneHistory(id)),
        flagScene: (id) => dispatch(sceneActions.flagSceneError(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SceneView);