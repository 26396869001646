import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { helper } from '../common/helper.js';
import Select from "react-dropdown-select";
import { sceneCardActions } from '../../actions/scene/sceneCardActions.js';
import '../App.css';
import SpinnerLoad from './spinner.js';
import MovieIcon from '@mui/icons-material/Movie';
import PersonIcon from '@mui/icons-material/Person';

function FilterScene(props) {

    const history = useHistory();
    const [direc, setDirec] = useState(props.params.direction);

    const url = (key, item) => {
        if (key === "sort")
            return `${props.baseUrl}&sort=${item}&direction=${direc}`;
        if (key === "direction")
            return `${props.baseUrl}&sort=${props.params.sort}&direction=${item.key}`;
    }

    const paramURL = (site, model, tag, movie, studio, company, direction, modelsort, sexe, status, positionCount) => {
        return `/scenes?site=${site}&movie=${movie}&studio=${studio}&company=${company}${modelsort!=null?`&modelsort=${modelsort}`:""}${sexe!=null?`&sexe=${sexe}`:""}${status!=null?`&status=${status}`:""}${model}${tag}&size=${props.params.size}&sort=${props.params.sort}&direction=${direction == null ? props.params.direction : direction}&dateReleased=${props.params.dateReleased}&search=${props.scenes.search}&positionCount=${props.params.positionCount}`;
    }

    const openFilterFunc = () => {
        if (props.openFilter === false)
            props.setOpenFilter(true);
        else
            props.setOpenFilter(false);
    }

    const onSearch = () => {
        history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status, props.params.positionCount));
    }

    const sceneRefresh = (value) => {
        onSearch();
        props.sceneRefresh(value);
    }

    const directionChange = (key, item) => {
        if (direc == 'asc') {
            setDirec('desc')
        }
        else {
            setDirec('asc')
        }
        props.filterRefresh(key, item, direc)
    }

    const itemRenderer = ({ item, itemIndex, props, state, methods }) => (
        <div style={{backgroundColor:`${methods.isSelected(item)?'black':'white'}`, color:`${methods.isSelected(item)?'white':'black'}` }} key={item[props.valueField]} onClick={() => methods.addItem(item)}>
          <div style={{ margin: "4px"}}>
          {item.tagType =='Scene'?<MovieIcon/>:<PersonIcon/>}
            &nbsp;&nbsp;&nbsp;{item[props.labelField]}
            
          </div>
        </div>
      );
    
    useEffect(() => {
        if (direc == 'asc') {
            setDirec('desc')
        }
        else {
            setDirec('asc')
        }
    }, [])

    return (<div className="row p-3">
        <div className="col-9">
            <input className="form-control" value={props.scenes.search} onChange={(e) => {
                props.setSceneFilter('SEARCH', e.target.value);
                onSearch();
            }} onKeyDown={(e) => e.key === "Enter" ? sceneRefresh(e.target.value) : ""} />
        </div>
        <div className="col">
            <button className="btn btn-secondary" onClick={(e) => sceneRefresh(props.scenes.search)}>Search</button>
        </div>
        <div className="col">
            {!props.fetching.filter && !props.fetching.scene && !props.fetching.count ?<button className="btn btn-secondary dropdown-toggle" type="button" onClick={openFilterFunc}>Filters</button>:<SpinnerLoad/>}
        </div>

        {props.openFilter && !props.fetching.filter && !props.fetching.scene && !props.fetching.count ?

            <div className="col">

                <div className="row p-1">
                    {!props.isFetching ?
                        <div className="col">
                            <label>Studio</label>
                            <Select options={props.studio.available} placeholder="Select Studio" labelField="nameScene" valueField="id"
                                color="#000000" searchable="true" clearable="true" searchBy="name" sortBy="name" dropdownHeight="450px" dropdownPosition="auto"
                                values={props.studio.selected == null ? [] : [props.studio.selected]}
                                onChange={(e) => {
                                    if (Array.isArray(e)) {
                                        if (e.length > 0) {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, e[0].id, props.params.company ));
                                        }
                                        else {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, -1, props.params.company))
                                            props.studioRefresh("-1")
                                        }
                                    }
                                }}
                            />
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" />
                        </div>}

                    {!props.isFetching ?
                        <div className="col">
                            <label>Sites</label>
                            <Select options={props.sites.available} placeholder="Select Site" labelField="nameScene" valueField="siteId"
                                color="#000000" searchable="true" clearable="true" searchBy="name" sortBy="name" dropdownHeight="450px" dropdownPosition="auto"
                                values={props.sites.selected == null ? [] : [props.sites.selected]}
                                onChange={(e) => {
                                    if (Array.isArray(e)) {
                                        if (e.length > 0) {
                                            history.push(paramURL(e[0].siteId, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                        }
                                        else {
                                            history.push(paramURL(-1, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                            props.site("-1")
                                        }
                                    }
                                }}
                            />
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}

                    {!props.isFetching ?
                        <div className="col">
                            <label>Movies</label>
                            <Select options={props.movies.available} placeholder="Select Movie" labelField="name" valueField="id"
                                color="#000000" searchable="true" clearable="true" searchBy="name" sortBy="name" dropdownHeight="450px" dropdownPosition="auto"
                                values={props.movies.selected == null ? [] : [props.movies.selected]}
                                onChange={(e) => {
                                    if (Array.isArray(e)) {
                                        if (e.length > 0) {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), e[0].id, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                        }
                                        else {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), -1, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status))
                                            props.movie("-1")
                                        }
                                    }
                                }}
                            />
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}

                    {!props.isFetching ?
                        <div className="col">
                            <label>Models</label>
                            <Select multi options={props.models.available} values={props.models.selected}
                                placeholder="Select a model..." labelField="nameScene" valueField="id"
                                color="#333" searchable="true" clearable="true" searchBy="name"
                                dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                onChange={(e) => {
                                    var temp = e.map(a => a.id).sort();
                                    history.push(paramURL(props.params.site, helper.query(temp, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                }} />
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}
                    {!props.isFetching ?
                        <div className="col">
                            <label>Tags</label>
                            <Select multi options={props.tags.available} values={props.tags.selected}
                                placeholder="Select a tag..." labelField="name" valueField="id"
                                color="#333" searchable="true" clearable="true" searchBy="fullName"
                                dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                itemRenderer={(item, itemIndex, props, state, methods) => itemRenderer(item, itemIndex, props, state, methods)}
                                onChange={(e) => {
                                    var temp = e.map(a => a.id);
                                    history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(temp, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                }} />
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}
                </div>
            </div> : <></>}
    </div>
    );
}

const mapStateToProps = (state) => {
    return {
        propp: state.sceneCardReducers,
        fetching: state.sceneCardReducers.fetching,
        isFetching: state.sceneCardReducers.fetching.filter,
        sites: state.sceneCardReducers.sites,
        movies: state.sceneCardReducers.movies,
        models: state.sceneCardReducers.models,
        studio: state.sceneCardReducers.studio,
        tags: state.sceneCardReducers.tags,
        openFilter: state.sceneCardReducers.openFilter,
        scenes: state.sceneCardReducers.sceneFilters
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOpenFilter: (val) => dispatch(sceneCardActions.setOpenFilter(val)),
        setSceneFilter: (param, val) => dispatch(sceneCardActions.setSceneFilter(param, val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterScene);