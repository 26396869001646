import React, { useEffect } from 'react';
import { CardDeck, Container, Row, Card, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { helper } from '../common/helper.js';
import queryString from 'query-string';

//local common
import SpinnerLoad from '../common/spinner';
import PaginationComponent from '../common/pagination';
import ItemDisplay from '../common/item-display';
import FilterActor from '../common/filter-actor';
import { actorCardActions } from '../../actions/actor/actorCardActions.js'
import constants, { PERM } from '../../const';
import NewActor from './new-actor-popup.js';
import ContentRequest from '../tv/ContentRequest.js';
import EditActor from './edit-actor-popup';

function Actors(props) {

    const { getActors } = props;
    let params = queryString.parse(props.location.search);
    if (params.page == null)
        params.page = 1;
    if (params.size == null)
        params.size = 20;
    if (params.sexe == null)
        params.sexe = "all";
    if (params.sort == null)
        params.sort = "recentActivity";
    if (params.direction == null)
        params.direction = "desc";
    if (params.search == null)
        params.search = "";

    if (params.tag == null)
        params.tag = [];
    if (!Array.isArray(params.tag))
        params.tag = [params.tag];

    var adminRole = props?.roles?.some(e => e.role.id === 1)

    useEffect(() => {
        getActors(params.sexe, params.sort, params.direction, params.size, params.page, params.tag, params.search);
        // eslint-disable-next-line
    }, [getActors]);

    const tag = (tagI) => {
        params.tag = tagI;
        getActors(params.sexe, params.sort, params.direction, params.size, params.page, tagI, params.search);
    };

    const sizeRefresh = (size) => {
        params.size = size;
        getActors(params.sexe, params.sort, params.direction, size, params.page, params.tag, params.search);
    };

    const filterRefresh = (key, item) => {
        if (key === "sort")
            getActors(params.sexe, item.key, params.direction, params.size, params.page, params.tag, params.search);
        if (key === "direction")
            getActors(params.sexe, params.sort, item.key, params.size, params.page, params.tag, params.search);
        if (key === "sexe")
            getActors(item.key, params.sort, params.direction, params.size, params.page, params.tag, params.search);
    }

    var filters = [
        {
            key: "sort",
            title: "Sort",
            items: [
                { key: "recentActivity", value: "Recent Activity" },
                { key: "sceneCount", value: "Number Of Scenes" },
                { key: "name", value: "Name" },
                { key: "rating", value: "Rating" },
                { key: "age", value: "Age" },
                { key: "id", value: "ID" }],
            selected: params.sort
        },
        {
            key: "direction",
            title: "Direction",
            items: [
                { key: "asc", value: "Ascending" },
                { key: "desc", value: "Descending" }],
            selected: params.direction
        },
        {
            key: "sexe",
            title: "Sexe",
            items: [
                { key: "F", value: "Female" },
                { key: "M", value: "Male" },
                { key: "all", value: "All" }],
            selected: params.sexe
        },
    ]
    if (!props.isFetching) {
        var actorCardElem = [];
        var colSize = 1;
        var token = `?token=${localStorage.getItem('token').slice(7)}`;
        var i = 0;
        for (var col = 0; col < Math.ceil(props.actors?.length / colSize); col++) {
            var cardDeck = [];
            for (var row = 0; row < colSize; row++) {
                var url = "/actor/" + props.actors[i].actor.id;
                if (i < props.actors.length) {
                    cardDeck.push(
                        <Card key={props.actors[i].actor.id}>
                            <Link to={url}><Card.Img variant="top" style={{ width: "200px", height: "270px" }} src={`${constants.CONTENT_DOMAIN}${props.actors[i].actor.lgURL}${token}`} /></Link>
                            <Card.Body style={{ padding: "5px" }}>
                                <li className="d-flex justify-content-between align-items-center" style={{ fontSize: "15px" }}>{props.actors[i].actor.name} 
                                    <span className="badge badge-secondary" style={{ bottom: "5px", right: "5px" }}> <Link to={url} className="scene-card-link" style={{color:"white"}}>{props.actors[i].actor.sceneCount}</Link></span>
                                    {adminRole && (props.actors[i].actor.status !== "COM" && props.actors[i].actor.status !== "SST") ? <Link to={url} className="badge badge-pill badge-danger">{props.actors[i].actor.status == null? 'missing':props.actors[i].actor.status}</Link> : <div />}
                                    {adminRole ? <EditActor actorId={props.actors[i].actor.id} />: null}
                                </li>
                                
                            </Card.Body>
                        </Card>);
                    i++;
                }
            }
            actorCardElem.push(<CardDeck style={{ padding: "10px" }} key={col}>{cardDeck}</CardDeck>);
        }
        const paginate = (pageNumber) => props.getActors(params.sexe, params.sort, params.direction, params.size, pageNumber, params.tag, params.search);
        return (
            <>
            <ContentRequest/>
                <Container style={{ maxWidth: `${window.innerWidth}px` }}>
                    <Container>

                        <FilterActor filters={filters} params={params}
                            baseUrl={`/actors${helper.tagQuery(params.tag, "tag")}&size=${params.size}`}
                            tag={tag}
                            filterRefresh={filterRefresh} />

                        <Row>
                            <Col className="p-3"><h3>{props.count} Actors</h3></Col>
                            {adminRole ? <Col className="p-3"><NewActor /></Col> : <></>}
                            <Col className="d-flex  justify-content-end p-3">
                                <ItemDisplay size={params.size} sizes={[20, 32, 50, 100]} baseUrl={`/actors${helper.tagQuery(params.tag, "tag")}&sexe=${params.sexe}&search=${params.search}&sort=${params.sort}&direction=${params.direction}&size=`}
                                sizeParam={sizeRefresh} />
                            </Col>
                        </Row>
                        </Container>

                        {props.count !== 0 ?
                            <div>
                                <Container style={{ maxWidth: `${window.innerWidth}px` }}>
                                    <Row className="justify-content-center">
                                        <PaginationComponent baseUrl={`/actors${helper.tagQuery(params.tag, "tag")}&sexe=${params.sexe}&search=${params.search}&sort=${params.sort}&direction=${params.direction}&size=${params.size}&page=`}
                                            params={params} totalItem={props.count} paginate={paginate} />
                                    </Row>
                                    <Row className="justify-content-center">{actorCardElem}</Row>
                                    <Row className="justify-content-center">
                                        <PaginationComponent baseUrl={`/actors${helper.tagQuery(params.tag, "tag")}&sexe=${params.sexe}&search=${params.search}&sort=${params.sort}&direction=${params.direction}&size=${params.size}&page=`}
                                            params={params} totalItem={props.count} paginate={paginate} />
                                    </Row></Container></div>
                            : ""}

                    
                </Container>
            </>
        )
    }
    else {
        return (<SpinnerLoad></SpinnerLoad>);
    }
}
const mapStateToProps = (state) => {
    return {
        roles: state.users.permissions.perms,
        isFetching: state.actorCardReducers.isFetching.actor,
        actors: state.actorCardReducers.actors,
        count: state.actorCardReducers.count
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getActors: (sexe, sort, direction, size, page, tag, search ) => dispatch(actorCardActions.getActorCards(sexe, sort, direction, size, page, tag, search))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Actors);

