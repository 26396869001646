import axios from 'axios';
import constants from '../../const.js';

const SCENE_LIKE_REQ = "SCENE_LIKE_REQ";
const SCENE_LIKE_SUC = "SCENE_LIKE_SUC";
const SCENE_LIKE_ERR = "SCENE_LIKE_ERR";

const like_REQ = () =>{
    return{
        type: SCENE_LIKE_REQ
    };
};

const like_SUC = (scenes) =>{
    var scenesList = []
    scenes.scenes.forEach(scene => {
        scenesList.push(scene.scene)
    });
    return{
        type: SCENE_LIKE_SUC,
        scenes: scenesList,
        // sites: scenes.sites,
        // models: scenes.models,
        // tags: scenes.tags,
        count: scenesList.length
    };
};

const like_ERR = (error) =>{
    return{
        type: SCENE_LIKE_ERR,
        error
    };
};

const getScenes = () =>{    
    return dispatch =>{
        dispatch(like_REQ());
        return axios.get(constants.USER_API.SCENE_LIKE_URL,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        })
        .then(res => {
            dispatch(like_SUC(res.data));
        })
        .catch(error=> dispatch(like_ERR(error)))
    };
};

export const userLikeSceneActions = {
    SCENE_LIKE_REQ,
    SCENE_LIKE_SUC,
    SCENE_LIKE_ERR,
    getScenes
};

