import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import constants from "../../const";

export default function VideoPlayer(props) {

    const vidRef = useRef(null);
    var token = `?token=${localStorage.getItem('token').slice(7)}`;

    const seek = (t) => {
        vidRef.current.currentTime = t + vidRef.current.currentTime
    }

    useEffect(() => {
        if (props.play) {
            vidRef.current.play();
        }
        else {
            vidRef.current.pause();
        }
    }, [props.play])

    useEffect(() => {
        seek(20)
    }, [props.seek])

    return <>{console.log(props.scene.scene)}
    {!props.isVR?
        <video ref={vidRef} id="myvideo" controlsList="nodownload"
            playsInline={true}
            poster={`${constants.TRAILER_CDN}${props.scene.scene.posterURL}`} 
            controls style={{ width: `${props.width - 5}px` }} muted={props.muted} loop 
            >
            {props.trailer ? <source src={`${constants.TRAILER_CDN}${props.scene.scene.videoURL}`} /> :
                <source src={`${constants.CONTENT_DOMAIN}${props.scene.scene.videoUrl}${token}`} />
            }
        </video>:
         <dl8-video id="myvideo" ref={vidRef}
         format={`${props.scene.scene.vrType === 'none' ? 'STEREO_180_LR' : props.scene.scene.vrType}`}
         cors-fallback-url={`${constants.CONTENT_DOMAIN}vr?file=${constants.CONTENT_DOMAIN}${props.scene.scene.videoUrl}${token}#t=${props.startTime}&${constants.CONTENT_DOMAIN}${props.scene.scene.posterUrl}${token}`}
         preferred-controls="MAGIC_WINDOW_CONTROLS"
         poster={`${constants.CONTENT_DOMAIN}${props.scene.scene.posterUrl}${token}`}>
         <dl8-action-button></dl8-action-button>
         <source src={`${constants.CONTENT_DOMAIN}${props.scene.scene.videoUrl}${token}#t=${props.startTime}`} type="video/mp4" />
     </dl8-video>}
    </>
}