import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
// import { BehaviorSubject } from 'rxjs';
import { TvContext } from '../../context/TvContext';

import { useButtplugService } from './useButtplugService';

export const FunscriptActionContext = createContext();

export const FunscriptActionProvider = ({ children }) => {

  const { tvState, setTvState } = useContext(TvContext);

  const { vibrateDevice, rotateDevice, linearDevice, devices } = useButtplugService();

  const [actions, setActions] = useState([]);
  const [lastActionIndex, setLastActionIndex] = useState(-1);
  // const funscriptDurationSubject = new BehaviorSubject(0);
  const [funscriptDuration, setFunscriptDuration] = useState(null);
  // const currentFunscriptSubject = new BehaviorSubject(undefined);
  const [currentFunscript, setCurrentFunscript] = useState(null);

  const loadFunscript = (funscript) => {
    console.log('loadFunscript:', funscript);
    if (funscript && funscript.actions && funscript.actions.length > 0) {
      const sortedActions = [...funscript.actions].sort((a, b) => a.at - b.at);
      console.log('loadFunscript-devices',devices);
      console.log('loadFunscript-sortedActions',sortedActions);
      console.log('loadFunscript-funscript.actions', funscript.actions);
      setActions(sortedActions);
      console.log('loadFunscript-actions',actions)
      setLastActionIndex(-1);
      const duration = funscript.actions[funscript.actions.length - 1].at;
      console.log('duration:', duration);
      // funscriptDurationSubject.next(duration);
      // currentFunscriptSubject.next(funscript);
    }
  };

  const printDevices = () => {
    console.log('devices:', devices);
  }



  const removeFunscript = () => {
    setActions([]);
    // funscriptDurationSubject.next(0);
    // currentFunscriptSubject.next(undefined);
  };

  const findActionIndexForTime = (currentMs) => {
    let low = 0;
    let high = actions.length - 1;
    let bestIndex = -1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (actions[mid].at <= currentMs) {
        bestIndex = mid;
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    return bestIndex;
  };

  const dispatchAction = async (currentAction) => {
    console.log('dispatchAction:', currentAction);
    if (!tvState.deviceSettings.sendActionsEnabled) return;

    const currentIndex = actions.indexOf(currentAction);
    if (currentIndex < 0) return;

    let strokeDurationMs = 500;
    if (currentIndex < actions.length - 1) {
      const nextAction = actions[currentIndex + 1];
      const rawGapMs = nextAction.at - currentAction.at;
      strokeDurationMs = Math.max(0, rawGapMs);
    }

    const position = Math.min(1, Math.max(0, currentAction.pos / 100));
    const speed = position;
    // const devices = getDevices();
    try {
      const prefs = tvState.deviceSettings.preferences;
      console.log('devices:', devices);
      console.log('prefs:', prefs);

      for (const device of devices) {
        console.log('device:', device);
        const devicePref = tvState.deviceSettings.preferences;
        console.log(device.canLinear, devicePref.useLinear);
        if (!devicePref || !devicePref.enabled) continue;

        if (device.canVibrate && devicePref.useVibrate) {
          await vibrateDevice(device.index, speed);
        }

        if (device.canRotate && devicePref.useRotate) {
          await rotateDevice(device.index, speed, true);
        }

        if (device.canLinear && devicePref.useLinear) {
          console.log('linearDevice:', device.index, position, strokeDurationMs);
          await linearDevice(device.index, position, strokeDurationMs);
        }
      }
    } catch (err) {
      console.error('Error sending action to device:', err);
    }
  };

  const checkTime = async (currentTimeMs) => {
    console.log('checkTime:', currentTimeMs);
    console.log(actions)
    if (!actions.length) return;

    const offset = tvState.deviceSettings.scriptTimingOffsetMs;
    const compensatedTime = currentTimeMs + offset;
    const actionIndex = findActionIndexForTime(compensatedTime);
    console.log(actionIndex);

    if (actionIndex !== -1 && actionIndex !== lastActionIndex) {
      const action = actions[actionIndex];
      await dispatchAction(action).then();
      setLastActionIndex(actionIndex);
    }
  };

  const reset = () => {
    setLastActionIndex(-1);
  };


  return (
    <FunscriptActionContext.Provider
      value={{
        loadFunscript,
        removeFunscript,
        checkTime,
        reset,
        printDevices,
        funscriptDuration,
        currentFunscript,
        actions
      }}
    >
      {children}
    </FunscriptActionContext.Provider>
  );
};


export const useFunscriptAction = () => {
  return useContext(FunscriptActionContext);
};
