import axios from 'axios';
import constants from '../../const.js';
import { commonReq } from '../common.js';
import { getScenes } from '../scene/sceneCardActions';


/**
 * Get single actor by it's id
 */
const ACTOR_REQUEST = "ACTOR_REQUEST";
const ACTOR_SUCCESS = "ACTOR_SUCCESS";
const ACTOR_FAILURE = "ACTOR_FAILURE";

const actorRequest = () => {
    return {
        type: ACTOR_REQUEST
    };
};

const actorSuccess = (actor) => {
    return {
        type: ACTOR_SUCCESS,
        actor: actor
    };
};

const actorFailure = (error) => {
    return {
        type: ACTOR_FAILURE,
        error
    }
};

const getActorById = (id) => {
    return dispatch => {
        dispatch(actorRequest());
        return axios.get(`${constants.DATA_API.ACTOR_URL}/${id}`)
            .then(res => {
                dispatch(actorSuccess(res.data))
            })
            .catch(error => dispatch(actorFailure(error)))
    }
};


/**
 * #Get Scenes by actor
 */
const ACTOR_SCENE_REQUEST = "ACTOR_SCENE_REQUEST";
const ACTOR_SCENE_SUCCESS = "ACTOR_SCENE_SUCCESS";
const ACTOR_SCENE_FAILURE = "ACTOR_SCENE_FAILURE";

const actorSceneRequest = () => {
    return {
        type: ACTOR_SCENE_REQUEST
    };
};

const actorSceneSuccess = (scenes) => {
    return {
        type: ACTOR_SCENE_SUCCESS,
        scenes: scenes.scenes,
        sceneCount: scenes.count
    };
};

const actorSceneFailure = (error) => {
    return {
        type: ACTOR_SCENE_FAILURE,
        error
    }
};
const getScenesByActor = (size, page, site, sort, direction, tag, model) => {
    return dispatch => dispatch(getScenes(size, page, site, sort, direction, tag, model, '', -1, -1, -1, '',null, null, null, "", actorSceneRequest, actorSceneSuccess, actorSceneFailure, 1));
};



/**
 * Create new Actor
 */
const ACTOR_CREATE_REQUEST = "ACTOR_CREATE_REQUEST";
const ACTOR_CREATE_SUCCESS = "ACTOR_CREATE_SUCCESS";
const ACTOR_CREATE_FAILURE = "ACTOR_CREATE_FAILURE";

const actorCreateRequest = () => {
    return {
        type: ACTOR_CREATE_REQUEST
    };
};
const actorCreateSuccess = (actor) => {
    return {
        type: ACTOR_CREATE_SUCCESS,
        actor: actor
    };
}

const actorCreateFailure = (error) => {
    return {
        type: ACTOR_CREATE_FAILURE,
        error: error
    };
}

/**
 * Insert new actor
 * @param {} actor 
 * @returns 
 */
const createActor = (actor) => {
    return dispatch => {
        dispatch(actorCreateRequest());
        return axios.post(`${constants.DATA1_API_URL}/actors`, actor)
            .then(res => {
                dispatch(actorCreateSuccess(res.data))
            })
            .catch(err => dispatch(actorCreateFailure(err)));
    };
}

/**
 * Update Actor
 * @param {} actor 
 * @returns 
 */
const updateActor = (actor)=>{
    return dispatch => {
        // dispatch(actorCreateRequest());
        return axios.put(`${constants.DATA1_API_URL}/actor/${actor.id}`, actor, {
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        })
            .then(res => {
                dispatch(getActorById(actor.id));
                dispatch(updateActorScenes(actor.id))
            })
            .catch(err => dispatch(actorFailure(err)));
    };
}

const updateActorScenes = (actor) =>{
    return dispatch => {
        return axios.get(`${constants.CONTENT_DOMAIN}update/actor/${actor}?token=${localStorage.getItem('token').slice(7)}`)
            .then(res => {
            })
            .catch(err => dispatch(actorFailure(err)));
    };
}


/**
 * Insert ActorTag to an Actor
 */
const INSERT_ACTOR_TAG_REQUEST = "INSERT_ACTOR_TAG_REQUEST";
const INSERT_ACTOR_TAG_SUCCESS = "INSERT_ACTOR_TAG_SUCCESS";
const INSERT_ACTOR_TAG_FAILURE = "INSERT_ACTOR_TAG_FAILURE";

const insertActorTag = (actorId, tagId) =>{
    return dispatch => {
        dispatch(commonReq.simpleRequest(INSERT_ACTOR_TAG_REQUEST));
        return axios.post(`${constants.DATA1_API_URL}/actor/${actorId}/tags/${tagId}`,{},{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        }).then(dispatch(commonReq.simpleSuccess(INSERT_ACTOR_TAG_SUCCESS)))
        .catch(err=> dispatch(commonReq.simpleFailure(INSERT_ACTOR_TAG_FAILURE, err)));
    };
}


/**
 * Remove ActorTag from an Actor
 */
 const REMOVE_ACTOR_TAG_REQUEST = "REMOVE_ACTOR_TAG_REQUEST";
 const REMOVE_ACTOR_TAG_SUCCESS = "REMOVE_ACTOR_TAG_SUCCESS";
 const REMOVE_ACTOR_TAG_FAILURE = "REMOVE_ACTOR_TAG_FAILURE";

 const removeActorTag = (actorId, tagId) =>{
    return dispatch => {
        dispatch(commonReq.simpleRequest(REMOVE_ACTOR_TAG_REQUEST));
        return axios.delete(`${constants.DATA1_API_URL}/actor/${actorId}/tags/${tagId}`,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        }).then(dispatch(commonReq.simpleSuccess(REMOVE_ACTOR_TAG_SUCCESS)))
        .catch(err=> dispatch(commonReq.simpleFailure(REMOVE_ACTOR_TAG_FAILURE, err)));
    };
}

/**
 * Get Actor's tag
 */
 const ONE_ACTOR_TAG_REQUEST = "ONE_ACTOR_TAG_REQUEST";
 const ONE_ACTOR_TAG_SUCCESS = "ONE_ACTOR_TAG_SUCCESS";
 const ONE_ACTOR_TAG_FAILURE = "ONE_ACTOR_TAG_FAILURE";


 const oneActorTagRequest = () => {
    return {
        type: ONE_ACTOR_TAG_REQUEST
    };
};
const oneActorTagSuccess = (tags) => {
    return {
        type: ONE_ACTOR_TAG_SUCCESS,
        tags: tags
    };
}

const oneActorTagFailure = (error) => {
    return {
        type: ONE_ACTOR_TAG_FAILURE,
        error: error
    };
}

const getActorTagByActor = (actorId) => {
    return dispatch => {
        dispatch(oneActorTagRequest());
        return axios.get(`${constants.DATA1_API_URL}/actor/${actorId}/tags`)
            .then(res => dispatch(oneActorTagSuccess(res.data)))
            .catch(error => dispatch(oneActorTagFailure(error)))
    }
}

/**
 * Insert Actor Alias
 */
 
 const INSERT_ACTOR_ALIAS_REQUEST = "INSERT_ACTOR_ALIAS_REQUEST";
 const INSERT_ACTOR_ALIAS_SUCCESS = "INSERT_ACTOR_ALIAS_SUCCESS";
 const INSERT_ACTOR_ALIAS_FAILURE = "INSERT_ACTOR_ALIAS_FAILURE";

 const insertActorAlias = (actorId, alias) =>{
     return dispatch => {
         dispatch(commonReq.simpleRequest(INSERT_ACTOR_ALIAS_REQUEST));
         return axios.post(`${constants.DATA1_API_URL}/actor/${actorId}/alias/${alias}`,{},{
             headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
         }).then(dispatch(commonReq.simpleSuccess(INSERT_ACTOR_ALIAS_SUCCESS)))
         .catch(err=> dispatch(commonReq.simpleFailure(INSERT_ACTOR_ALIAS_FAILURE, err)));
     };
 }

 const removeActorAlias = (actorId, alias) =>{
    return dispatch => {
        dispatch(commonReq.simpleRequest(REMOVE_ACTOR_TAG_REQUEST));
        return axios.delete(`${constants.DATA1_API_URL}/actor/${actorId}/alias/${alias}`,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        }).then(dispatch(commonReq.simpleSuccess(REMOVE_ACTOR_TAG_SUCCESS)))
        .catch(err=> dispatch(commonReq.simpleFailure(REMOVE_ACTOR_TAG_FAILURE, err)));
    };
}

 

export const actorActions = {
    ACTOR_REQUEST,
    ACTOR_SUCCESS,
    ACTOR_FAILURE,
    getActorById,

    ACTOR_SCENE_REQUEST,
    ACTOR_SCENE_SUCCESS,
    ACTOR_SCENE_FAILURE,
    getScenesByActor,

    ACTOR_CREATE_REQUEST,
    ACTOR_CREATE_SUCCESS,
    ACTOR_CREATE_FAILURE,
    createActor,
    updateActor,

    INSERT_ACTOR_TAG_REQUEST,
    INSERT_ACTOR_TAG_SUCCESS,
    INSERT_ACTOR_TAG_FAILURE,
    insertActorTag,

    REMOVE_ACTOR_TAG_REQUEST,
    REMOVE_ACTOR_TAG_SUCCESS,
    REMOVE_ACTOR_TAG_FAILURE,
    removeActorTag,

    ONE_ACTOR_TAG_REQUEST,
    ONE_ACTOR_TAG_SUCCESS,
    ONE_ACTOR_TAG_FAILURE,
    getActorTagByActor,

    INSERT_ACTOR_ALIAS_REQUEST,
    INSERT_ACTOR_ALIAS_SUCCESS,
    INSERT_ACTOR_ALIAS_FAILURE,
    insertActorAlias,
    removeActorAlias
};
