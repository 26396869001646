import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { loginActions } from '../actions/user/loginActions';
import constants from '../const.js';
import { TvContext } from '../context/TvContext';

const Header = (props) => {

    const { tvState, setTvState } = useContext(TvContext);

    var isMobile = window.innerWidth <= constants.mobileSize;
    window.addEventListener('resize', e => isMobile = window.innerWidth <= constants.mobileSize);

    const setGroupSession = () => {
        if (tvState.groupChat.active) {
            setTvState({
                ...tvState,
                groupChat: {
                    ...tvState.groupChat,
                    active: false
                }
            })
        }
        else {
            setTvState({
                ...tvState,
                groupChat: {
                    ...tvState.groupChat,
                    active: true
                }
            })
        }
    }

    const setRequest = () => {
        if (tvState.contentRequest.active) {
            setTvState({
                ...tvState,
                contentRequest: {
                    ...tvState.contentRequest,
                    active: false
                }
            })
        } else {
            setTvState({
                ...tvState,
                contentRequest: {
                    ...tvState.contentRequest,
                    active: true
                }
            })
        }
    }

    const setDeviceSettings = () =>{
        if (tvState.deviceSettings.active) {
            setTvState({
                ...tvState,
                deviceSettings: {
                    ...tvState.deviceSettings,
                    active: false
                }
            })
        } else {
            setTvState({
                ...tvState,
                deviceSettings: {
                    ...tvState.deviceSettings,
                    active: true
                }
            })
        }
    }

    const fullMenu = <div>
        <Navbar bg="dark" variant="dark" className="bg-body-tertiary" sticky="top">
            <Container>
                <Navbar.Brand href="/">66Movies</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Link to="/movies" className="nav-link" role="Button">Movies</Link>
                        <Link to="/shows" className="nav-link" role="Button">Shows</Link>
                        {props.authorized && props.perms.some(e => e.role.id === 3) ? <Link to="/scenes" className="nav-link" role="Button">Scenes</Link> : ""}
                        {props.authorized && props.perms.some(e => e.role.id === 3) ? <Link to="/galleries" className="nav-link" role="Button">Galleries</Link> : ""}
                        {props.authorized && props.perms.some(e => e.role.id === 3) ? <Link to="/actors" className="nav-link" role="Button">Actors</Link> : ""}
                        {props.authorized && props.perms.some(e => e.role.id === 13) ? <Link to="/map" className="nav-link" role="Button">Maps</Link> : ""}
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <div className="justify-content-end">
                        {props.authorized ? (<div>
                            <button className={`btn btn-outline-success my-2 my-sm-0 ${tvState.contentRequest.active ? "active" : ""}`} style={{ margin: "10px" }} onClick={() => setRequest()}>Request</button>
                            <button className={`btn btn-outline-success my-2 my-sm-0 ${tvState.deviceSettings.active ? "active" : ""}`} style={{ margin: "10px" }} onClick={() => setDeviceSettings()}>Devices</button>
                            {/* <button className={`btn btn-outline-success my-2 my-sm-0 ${tvState.groupChat.active ? "active" : ""}`} style={{ margin: "10px" }} onClick={() => setGroupSession()}>Party</button> */}
                            {/* <Link className="btn btn-outline-success my-2 my-sm-0" to="/profile/account" style={{ margin: "10px" }}>My Account</Link> */}

                            <NavDropdown className="btn my-2 my-sm-0" style={{ color: "black" }} title="My Account" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#/profile/account">Profile</NavDropdown.Item>
                                {props.perms.some(e => e.role.id === 1) ? <NavDropdown.Item href="#/admin">Admin</NavDropdown.Item> : ""}
                                {props.authorized && props.perms.some(e => e.role.id === 3) ? <NavDropdown.Item href="#/profile/playlistScene">Playlists</NavDropdown.Item> : null}
                                {props.authorized && props.perms.some(e => e.role.id === 3) ? <NavDropdown.Item href="#/profile/favoriteScene">Favorites</NavDropdown.Item> : null}
                                {props.authorized && props.perms.some(e => e.role.id === 3) ? <NavDropdown.Item href="#/profile/historyScene">History</NavDropdown.Item> : null}
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={props.logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </div>) :
                            (<div>
                                <Link className="btn btn-outline-success my-2 my-sm-0" to="/login" style={{ margin: "10px" }}>Login</Link>
                                <Link className="btn btn-outline-success my-2 my-sm-0" to="/signup" >Sign Up</Link></div>)}
                    </div>
                </Navbar.Collapse>
            </Container>
            {/* <Link to="/" className="navbar-brand">66Movies</Link> */}


        </Navbar>
    </div>;

    const mobileMenu = <div>
        <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
            <Link to="/" className="navbar-brand">66Movies</Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Link to="/movies" className="nav-link">Movies</Link>
                    <Link to="/shows" className="nav-link">Shows</Link>
                    {props.authorized && props.perms.some(e => e.role.id === 3) ? <Link to="/scenes" className="nav-link" role="Button">Scenes</Link> : ""}
                    {props.authorized && props.perms.some(e => e.role.id === 3) ? <Link to="/galleries" className="nav-link" role="Button">Galleries</Link> : ""}
                    {props.authorized && props.perms.some(e => e.role.id === 3) ? <Link to="/actors" className="nav-link" role="Button">Actors</Link> : ""}
                    {props.authorized && props.perms.some(e => e.role.id === 13) ? <Link to="/map" className="nav-link" role="Button">Maps</Link> : ""}
                    {props.authorized ? (<div>
                        <button className={`btn btn-outline-success my-2 my-sm-0 ${tvState.contentRequest.active ? "active" : ""}`} style={{ margin: "10px" }} onClick={() => setRequest()}>Request</button>
                        {/* <button className={`btn btn-outline-success my-2 my-sm-0 ${tvState.groupChat.active ? "active" : ""}`} style={{ margin: "10px" }} onClick={() => setGroupSession()}>Party</button> */}
                        {/* <Link className="btn btn-outline-success my-2 my-sm-0" to="/profile" style={{ marginLeft: "-5px", marginRight: "10px" }}>My Account</Link> */}
                        <NavDropdown className="btn my-2 my-sm-0" title="My Account" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#/profile/account">Profile</NavDropdown.Item>
                            {props.perms.some(e => e.role.id === 1) ? <NavDropdown.Item href="#/admin">Admin</NavDropdown.Item> : ""}
                            {props.authorized && props.perms.some(e => e.role.id === 3) ? <NavDropdown.Item href="#/profile/playlistScene">Playlists</NavDropdown.Item> : null}
                            {props.authorized && props.perms.some(e => e.role.id === 3) ? <NavDropdown.Item href="#/profile/favoriteScene">Favorites</NavDropdown.Item> : null}
                            {props.authorized && props.perms.some(e => e.role.id === 3) ? <NavDropdown.Item href="#/profile/historyScene">History</NavDropdown.Item> : null}
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={props.logout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </div>) :
                        (<div>
                            <Link className="btn btn-outline-success" to="/login" style={{ marginLeft: "-5px", marginRight: "10px" }}>Login</Link>
                            <Link className="btn btn-outline-success my-2 my-sm-0" to="/signup" >Sign Up</Link></div>)}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </div>;

    return (
        <div>
            {!tvState.media.playing ?
                <>{isMobile ? mobileMenu : fullMenu}</>
                : null}
            <deviceSettings/>
        </div>
    )
}
const mapStateToProps = function (state) {
    return {
        authorized: state.users.user.authorized,
        perms: state.users.permissions.perms
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(loginActions.logout())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)

