import React, { useState, useEffect } from "react";
import SimpleGallery from "./SimpleGallery";
import SpinnerLoad from "../common/spinner";

import "photoswipe/dist/photoswipe.css";
import { fetchGalleryAPI } from "../../controller/galleryController";


function ImageGalleryView(props) {

  const [gallery, setGallery] = useState({contents:[] ,children:{}});
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [galleryHTML, setGalleryHTMl] = useState(null);


  const fetchGalleryCallback = (data) =>{
    setGallery(data);
    setReadyToLoad(true);
  }

  useEffect(() => {
    fetchGalleryAPI(props.id, fetchGalleryCallback)
  }, []);

  useEffect(() => {
    const fetchDimension = async () => {
      if (readyToLoad){
        setGalleryHTMl(<SimpleGallery galleryID={"psp-gallery"} album={props.id} images={gallery} params={props.params} displayAlbum={props.params.displayAlbum}/>)
      }
    };

    if (gallery != null) {
      fetchDimension().catch(console.error);
    }
  },[gallery, readyToLoad, props.params.album]);


  return (
    <>
    {console.log(props.params)}
      {readyToLoad ? (galleryHTML) : <SpinnerLoad />}
    </>
  );
}

export default ImageGalleryView;
