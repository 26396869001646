import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { renderActions, renderHeatmap } from "funscript-utils/lib/funMapper";
import { useFunscriptAction } from "./FunscriptActionService";

const FunscriptHeatmapComponent = ({ funscriptUrl, widthPx, heightPx }) => {
  const heatmapRef = useRef(null);
  const actionmapRef = useRef(null);

  const { loadFunscript } = useFunscriptAction();
   const [funscript, setFunscript] = useState({});

  const fetchFunscript = async () => {
    await axios.get(funscriptUrl)
      .then((res) => {
        setFunscript(res.data)
        loadFunscript(res.data)
        generateHeatMap(res.data)
      })
  }

  useEffect(() => {
    fetchFunscript();
  }, []);  

  useEffect(() => {
    if (funscript) {
      generateHeatMap(funscript);
      // generateActiomMap(funscript);
      loadFunscript(funscript);
    }
  }, [funscript]);

  const generateHeatMap = async (funscript) => {
    await new Promise((resolve) => {
      renderHeatmap(heatmapRef.current, funscript, { showStrokeLength: false });
      setTimeout(resolve, 1000);
    });
  };

  const generateActiomMap = async (funscript, options = {}) => {
    await new Promise((resolve) => {
      renderActions(actionmapRef.current, funscript, {
        background: "white"
      });
      setTimeout(resolve, 1000);
    });
  };

  return (
    <div style={{ width: `${widthPx}`, height: `${heightPx}` }}>
      <canvas
        style={{ width: `${widthPx}`, height: `${heightPx}` }}
        ref={heatmapRef} />
    </div>
  );
};

FunscriptHeatmapComponent.propTypes = {
  funscript: PropTypes.object.isRequired,
  widthPx: PropTypes.number.isRequired,
  heightPx: PropTypes.number.isRequired,
};

export default FunscriptHeatmapComponent;