import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import constants from "../../const";
import { helper } from "../common/helper";
import ScenesDropDownMenu from "./ScenesDropDownMenu";

export default function PlaylistCard(props) {
  var images = [];
  if (props.playlist.scenes) {
    for (var img in props.playlist.scenes) {
      if (img > 3) break;
      images.push(
        `https://sus-store.s3.us-east-2.amazonaws.com/sus-media/scenes/${helper.pad(
          props.playlist.scenes[img],
          4
        )}/preview.webp`
      );
    }
  }
  let date = new Date(props.playlist?.created);
  date.setDate(date.getDate())
  let formattedDate = date.getDate() + "-" + constants.MONTHS[date.getMonth()] + "-" + date.getFullYear();

  const styleTime = {
    position: "absolute",
    right: "10px",
    top: "170px",
    paddingRight: "4px",
    paddingBottom: "6px",
    color: "#fff",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: "0.8",
  };
  const styleView = {
    position: "absolute",
    left: "5px",
    top: "5px",
    paddingRight: "4px",
    paddingBottom: "6px",
    color: "#fff",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: "0.5",
    fontSize: "16px",
  };

  const styleCount = {
    paddingLeft: "5px",
    paddingRight: "20px",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: "1",
    fontSize: "16px",
    fontWeight: "bold"
  };


  return (
    <Card style={{ width: "351px" }}>
      <Link
        style={{ height: "200px", width: "350px"}}
        to={`/playlist/${props.playlist.id}`}>
        <img src={images[0]} style={{height: "100px", width: "175px"}}></img>
        <img src={images[1]} style={{height: "100px", width: "175px"}}></img>
        <img src={images[2]} style={{height: "100px", width: "175px"}}></img>
        <img src={images[3]} style={{height: "100px", width: "175px"}}></img>
        <div style={styleTime}>
          <span className="badge badge-dark">{formattedDate}</span>
        </div>
        <div style={styleView}>
          <span className="badge badge-dark">{`0 views`}</span>
        </div>
      </Link>
      <Row className="pl-1 pt-1">
        <Col>
          <Link className="scene-card-link auto" to={`/playlist/${props.playlist.id}`}>
            <div className="m-2 card-title h5" style={{ fontSize: "14px" }}>
              {props.playlist.title}
            </div>
          </Link>
        </Col>
        {props.playlist.privacy == 1?<VisibilityIcon/>:<VisibilityOffIcon/>}
        <div style={styleCount}>
          <span className="" >{`${props.playlist.scenes ? props.playlist.scenes.length : 0} scenes`}</span>
        </div>
        <ScenesDropDownMenu settings={props.settings} refresh={props.refresh}/>
        </Row>
    </Card>
  );
}
