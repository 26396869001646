import React, { useState, useEffect } from "react";
import { Form, Col, InputGroup, Modal, Button, Row, Dropdown } from "react-bootstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { fetchPrivacyTypeAPI, updatePlaylistAPI } from "../../controller/userController";

export default function EditPlaylist(props) {
  const [show, setShow] = useState(false);
  const [privacy, setPrivacy] = useState([]);
  const [tagText, setTagText] = useState("");
  const [playlistInfo, setPlaylistInfo] = useState({
    title: "",
    tags: [],
    privacy: 1,
  });

  const updatePlaylistCallback = () =>{
    props.refresh()
  }

  useEffect(() => {
    fetchPrivacyTypeAPI(setPrivacy);
    if (props.playlist.tags == null){
      props.playlist.tags = [];
      setPlaylistInfo(props.playlist)
    }
    else{
      setPlaylistInfo(props.playlist)
    }
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setShow(false);
  };

  const handleSave = () => {
    updatePlaylistAPI(playlistInfo, updatePlaylistCallback);
    props.refresh()
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const addTag = () =>{
    var temp = playlistInfo.tags
    temp.push(tagText)
    setPlaylistInfo({
      ...playlistInfo,
      tags: temp,
    })
  }

  const removeTag = (e) =>{
    setPlaylistInfo({
        ...playlistInfo,
        tags: playlistInfo.tags.filter(a => a != e.target.getAttribute('value'))
      })
  }


  var privacySelect = React.forwardRef(() => (
    <select className="custom-select custom-select-sm" value={playlistInfo.privacy}
      onChange={(e) => setPlaylistInfo({ ...playlistInfo, privacy: parseInt(e.target.value) })} >
      {privacy?.map(pv => <option key={pv.privacy_id} value={pv.privacy_id}>{pv.privacy_name}</option>)}
    </select>
  ));

  return (
    <>
    {props.buttonType=='dropdown'?<Dropdown.Item onClick={handleShow}>Edit Playlist</Dropdown.Item>:null}
    {props.buttonType=='verticon'? <MoreVertIcon onClick={handleShow}/>:null}
      <Modal size="lg" show={show} onHide={handleCancel} style={{ backgroundColor: "hsla(0, 0%, 0%, 0.9)" }}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit Playlist: {props.playlist.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Group as={Col} md="10" controlId="validationTitle">
                <Form.Label srOnly>Title</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Title</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control required type="text" placeholder="" defaultValue={playlistInfo.title}
                    onChange={(e) =>
                      setPlaylistInfo({
                        ...playlistInfo,
                        title: e.target.value,
                      })} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please choose a valid Titl</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs="4" controlId="validationPrivacy">
                <Form.Label srOnly>Privacy</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Privacy</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as={privacySelect} required custom />
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="validationTags">
                <Form.Label srOnly>Tags</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Tags</InputGroup.Text>
                    <Form.Control type="text" defaultValue={tagText} onChange={(e) => setTagText(e.target.value)} />
                    <AddCircleOutlineIcon onClick={addTag} style={{ margin: "auto" }} />
                  </InputGroup.Prepend>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a valid Title
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Row style={{ marginTop: "10px" }}>
                  <Col>
                    {playlistInfo.tags.map(tag => (
                      <div key={tag} className="m-1 badge badge-secondary">
                        <span> <a value={tag} style={{ color: "white" }} onClick={removeTag}>{tag}</a> </span>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Form.Group>
            </Form.Row>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
